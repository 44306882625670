import React, { useState } from 'react';
import { 
  Calendar,
  FileText, 
  Folder,
  MessageSquare,
  MoreVertical,
  Edit2,
  Trash2,
  ChevronDown
} from 'lucide-react';

const ProjectCard = ({ project, onClick, onEdit, onDelete }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const getUniqueDocumentCount = () => {
    // If projectWideConversation doesn't have documents array, return regular count
    if (!project.projectWideConversation?.documents) {
      return project.documentCount || 0;
    }

    // Get unique documents by fileName
    const uniqueDocs = new Set(
      project.projectWideConversation.documents.map(doc => doc.fileName)
    );

    return uniqueDocs.size;
  };

  const getActivityText = () => {
    const count = getUniqueDocumentCount();
    return `${count} document${count !== 1 ? 's' : ''}`;
  };

  const formatDate = (date) => {
    const now = new Date();
    const projectDate = new Date(date);
    const diffTime = Math.abs(now - projectDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'Created today';
    if (diffDays === 1) return 'Created yesterday';
    if (diffDays < 7) return `Created ${diffDays} days ago`;
    return `Created on ${projectDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: projectDate.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
    })}`;
  };

  return (
    <div className="group relative">
      <div
        onClick={onClick}
        className="bg-white dark:bg-gray-800 border-2 border-gray-100 dark:border-gray-700 rounded-xl hover:border-[#464EB8] dark:hover:border-[#464EB8] transition-all duration-200 hover:shadow-lg"
      >
        <div className="p-6">
          <div className="flex items-start justify-between mb-4">
            <div className="flex items-start space-x-4">
              <div className="p-3.5 bg-[#464EB8]/10 rounded-lg">
                <Folder className="h-7 w-7 text-[#464EB8]" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-[#464EB8] transition-colors">
                  {project.name}
                </h3>
                <div className="flex items-center mt-2 space-x-2">
                  <Calendar size={16} className="text-gray-400" />
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {formatDate(project.createdAt)}
                  </span>
                </div>
              </div>
            </div>
            
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdown(!showDropdown);
              }}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors cursor-pointer"
            >
              <MoreVertical size={20} className="text-gray-500 dark:text-gray-400" />
            </button>
          </div>

          {project.description && (
            <p className="mt-3 text-gray-600 dark:text-gray-400 text-sm line-clamp-2 leading-relaxed">
              {project.description}
            </p>
          )}

          <div className="mt-6 flex items-center gap-6">
            <div className="flex items-center space-x-2">
              <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded-lg">
                <FileText size={18} className="text-gray-500 dark:text-gray-400" />
              </div>
              <div className="text-sm">
                <span className="text-gray-600 dark:text-gray-300 font-medium">
                  {getActivityText()}
                </span>
              </div>
            </div>
            {project.projectWideConversation && (
              <div className="flex items-center space-x-2">
                <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded-lg">
                  <MessageSquare size={18} className="text-gray-500 dark:text-gray-400" />
                </div>
                <div className="text-sm">
                  <span className="text-gray-600 dark:text-gray-300 font-medium">
                    {project.projectWideConversation.history?.length || 0} messages
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="px-6 py-4 bg-gray-50 dark:bg-gray-800/50 border-t dark:border-gray-700 flex items-center justify-between">
          <div className="text-sm text-gray-600 dark:text-gray-400">
            Last updated {new Date(project.updatedAt || project.createdAt).toDateString()}
          </div>
          <div className="flex items-center text-[#464EB8] dark:text-[#5B63D9] font-medium cursor-pointer">
            View
            <ChevronDown size={18} className="ml-2 transform -rotate-90" />
          </div>
        </div>

        {showDropdown && (
          <div className="absolute top-0 right-0 mt-14 mr-4 w-48 bg-white dark:bg-gray-800 rounded-xl shadow-xl border border-gray-200 dark:border-gray-700 z-10 overflow-hidden">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(project);
                setShowDropdown(false);
              }}
              className="w-full px-4 py-3 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center gap-3 cursor-pointer"
            >
              <Edit2 size={16} />
              Edit Project
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(project);
                setShowDropdown(false);
              }}
              className="w-full px-4 py-3 text-left text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 flex items-center gap-3 border-t border-gray-100 dark:border-gray-700 cursor-pointer"
            >
              <Trash2 size={16} />
              Delete Project
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;