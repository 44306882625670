// components/auth/EmailVerification.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { CheckCircle, XCircle, Loader, ArrowRight } from 'lucide-react';

const EmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState('loading'); // loading, success, error
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
        try {
          console.log('Starting email verification...');
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/verify-email/${token}`);
          
          // Handle both fresh verification and already verified cases
          if (response.data.alreadyVerified) {
            console.log('Email was already verified');
          }
          setVerificationStatus('success');
          
        } catch (error) {
          console.error('Verification Error:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status,
            url: error.config?.url
          });
          setVerificationStatus('error');
        }
      };

    console.log('Email Verification component mounted');
    verifyEmail();
  }, [token]);


  const renderContent = () => {
    switch (verificationStatus) {
      case 'loading':
        return {
          icon: <Loader className="w-12 h-12 text-[#00FFB2] animate-spin" />,
          title: 'Verifying Email',
          message: 'Please wait while we verify your email address...'
        };
      case 'success':
        return {
          icon: <CheckCircle className="w-12 h-12 text-[#00FFB2]" />,
          title: 'Email Verified',
          message: 'Your email has been successfully verified. You can now log in to your account.'
        };
      case 'error':
        return {
          icon: <XCircle className="w-12 h-12 text-red-500" />,
          title: 'Verification Failed',
          message: 'The verification link is invalid or has expired. Please try again or request a new verification email.'
        };
      default:
        return null;
    }
  };

  const content = renderContent();

  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white flex items-center justify-center relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#0A0A0A_1px,transparent_1px),linear-gradient(to_bottom,#0A0A0A_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)] opacity-25">
          <div className="absolute inset-0 bg-gradient-to-r from-[#00FFB2]/10 to-[#00D1FF]/10" />
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="w-full max-w-md bg-white/5 backdrop-blur-sm rounded-2xl border border-white/10 p-8 relative overflow-hidden"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-[#00FFB2]/10 to-[#00D1FF]/10 opacity-50" />

        <div className="relative space-y-6 text-center">
          {content.icon}
          <h2 className="text-2xl font-bold">{content.title}</h2>
          <p className="text-gray-400">{content.message}</p>

          {verificationStatus !== 'loading' && (
            <motion.button
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
              onClick={() => navigate('/login')}
              className="w-full bg-gradient-to-r from-[#00FFB2] to-[#00D1FF] text-black font-medium py-3 rounded-xl flex items-center justify-center gap-2 group"
            >
              <span>Go to Login</span>
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </motion.button>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default EmailVerification;