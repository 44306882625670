import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Send,
  Loader,
  Bot,
  User,
  Copy,
  Check,
  ThumbsUp,
  ThumbsDown,
  RefreshCw,
  Share2,
  FileText,
  Smile,
  Download,
  AlertCircle,
  Globe,
  Link,
  ChevronLeft,
} from "lucide-react";
import { useAuth } from "../context/AuthContext";
import ResponseRenderer from "./ResponseRenderer";

const downloadResponse = async (content) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/download/docx`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ content }),
      }
    );

    if (!response.ok) throw new Error("Download failed");

    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const uuid = crypto.randomUUID();
    const filename = `Lambda-AI-Assistant-${uuid}-${timestamp}.docx`;

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Download error:", error);
    throw error;
  }
};

const MessageActions = ({
  onCopy,
  onShare,
  onRegenerate,
  onDownload,
  isResponse,
  isRegenerating = false,
}) => {
  const [copied, setCopied] = useState(false);
  const [liked, setLiked] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const handleCopy = () => {
    onCopy();
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleDownload = async () => {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  };

  return (
    <div className="flex items-center gap-1 sm:opacity-0 sm:group-hover:opacity-100 opacity-100 transition-opacity">
      <div className="flex flex-wrap items-center gap-1">
        <button
          onClick={handleCopy}
          className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
          title={copied ? "Copied!" : "Copy message"}
        >
          {copied ? <Check size={14} /> : <Copy size={14} />}
        </button>
        {isResponse && (
          <>
            <button
              onClick={onRegenerate}
              className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
              title="Regenerate response"
              disabled={isRegenerating}
            >
              <RefreshCw
                size={14}
                className={isRegenerating ? "animate-spin" : ""}
              />
            </button>
            <button
              onClick={handleDownload}
              className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
              title="Download response"
              disabled={downloading}
            >
              {downloading ? (
                <Loader size={14} className="animate-spin" />
              ) : (
                <Download size={14} />
              )}
            </button>
          </>
        )}
        <button
          onClick={onShare}
          className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
          title="Share message"
        >
          <Share2 size={14} />
        </button>
        <button
          onClick={() => setLiked(true)}
          className={`p-1.5 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors ${
            liked === true
              ? "text-green-500 dark:text-green-400"
              : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
          }`}
          title="Helpful"
        >
          <ThumbsUp size={14} />
        </button>
        <button
          onClick={() => setLiked(false)}
          className={`p-1.5 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors ${
            liked === false
              ? "text-red-500 dark:text-red-400"
              : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
          }`}
          title="Not helpful"
        >
          <ThumbsDown size={14} />
        </button>
      </div>
    </div>
  );
};

const formatTime = (timestamp) => {
  return new Date(timestamp).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

const ChatInterface = ({
  conversationId,
  projectId,
  isProjectWide,
  currentConversation,
  isGeneral = false,
}) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [relevantDocs, setRelevantDocs] = useState([]);
  const [regenerating, setRegenerating] = useState(false);
  const [showShareAlert, setShowShareAlert] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const handleError = useCallback(
    (err) => {
      if (err.message === "Unauthorized") {
        logout();
        navigate("/login");
      } else {
        setError(err.message);
      }
    },
    [logout, navigate]
  );

  const fetchChatHistory = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      let endpoint;

      if (isGeneral) {
        endpoint = `/query/chat-history/${conversationId}`;
      } else if (projectId && isProjectWide) {
        endpoint = `/projectwide/chat/${projectId}/history`;
      } else if (conversationId && conversationId.startsWith("web-")) {
        endpoint = `/webcontent/chat/${conversationId}/history`;
      } else {
        endpoint = `/query/history/${conversationId}`;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        throw new Error("Unauthorized");
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      const filteredMessages = (data.history || []).filter(
        (m) => m.question || m.answer
      );
      setMessages(filteredMessages);

      if (data.warning) {
        setError(data.warning);
      }
    } catch (err) {
      handleError(err);
    }
  }, [projectId, isProjectWide, conversationId, handleError, isGeneral]);

  useEffect(() => {
    fetchChatHistory();
    setRelevantDocs([]);
  }, [fetchChatHistory]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const regenerateResponse = async (questionText) => {
    setRegenerating(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      let endpoint = "";
      let payload = {};

      if (isGeneral) {
        endpoint = "/query/general-query";
        payload = {
          question: questionText,
          conversationId,
        };
      } else if (projectId && isProjectWide) {
        endpoint = "/projectwide/chat/query";
        payload = {
          question: questionText,
          projectId,
        };
      } else if (conversationId) {
        endpoint = "/query/process";
        payload = {
          question: questionText,
          conversationId,
        };
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.status === 401) {
        throw new Error("Unauthorized");
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[newMessages.length - 1] = {
          ...newMessages[newMessages.length - 1],
          answer: data.history[data.history.length - 1].answer,
        };
        return newMessages;
      });

      if (data.relevantDocuments) {
        setRelevantDocs(data.relevantDocuments);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRegenerating(false);
    }
  };

  const shareResponse = async (question, answer) => {
    try {
      const shareData = {
        title: "Lambda AI Response",
        text: `Q: ${question}\n\nA: ${answer}`,
        url: window.location.href,
      };

      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(shareData.text);
        setShowShareAlert(true);
        setTimeout(() => setShowShareAlert(false), 3000);
      }
    } catch (error) {
      console.error("Error sharing:", error);
      setError("Failed to share response");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || loading) return;

    const messageText = inputMessage;
    setInputMessage("");
    setLoading(true);
    setError(null);

    const newMessage = {
      question: messageText,
      answer: "",
      timestamp: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, newMessage]);

    try {
      const token = localStorage.getItem("token");
      let endpoint = "";
      let payload = {};

      if (isGeneral) {
        endpoint = "/query/general-query";
        payload = {
          question: messageText,
          conversationId,
        };
      } else if (projectId && isProjectWide) {
        endpoint = "/projectwide/chat/query";
        payload = {
          question: messageText,
          projectId,
        };
      } else if (conversationId && conversationId.startsWith("web-")) {
        endpoint = "/webcontent/chat";
        payload = {
          question: messageText,
          chatId: conversationId,
        };
      } else if (conversationId) {
        endpoint = "/query/process";
        payload = {
          question: messageText,
          conversationId,
        };
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.status === 401) {
        throw new Error("Unauthorized");
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      setMessages(data.history);
      if (data.relevantDocuments) {
        setRelevantDocs(data.relevantDocuments);
      }

      if (data.warning) {
        setError(data.warning);
      }
    } catch (err) {
      handleError(err);
      setMessages((prev) => prev.filter((msg) => msg !== newMessage));
    } finally {
      setLoading(false);
    }
  };

  const copyMessage = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const handleDownload = async (content) => {
    try {
      await downloadResponse(content);
    } catch (error) {
      setError("Failed to download response");
    }
  };

  const renderWelcomeMessage = () => (
    <div className="flex items-center justify-center h-full max-w-2xl mx-auto px-4">
      <div className="text-center p-4 sm:p-6">
        {currentConversation?.isWebContent ? (
          <Globe
            size={40}
            className="text-indigo-600 dark:text-indigo-400 mb-4 mx-auto"
          />
        ) : (
          <Bot
            size={40}
            className="text-indigo-600 dark:text-indigo-400 mb-4 mx-auto"
          />
        )}
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
          {isGeneral
            ? "General Chat"
            : isProjectWide
            ? "Project-Wide Chat"
            : currentConversation?.isWebContent
            ? currentConversation.fileName || "Web Content Chat"
            : `Document Chat: ${currentConversation?.fileName}`}
        </h3>
        {currentConversation?.isWebContent && currentConversation?.url && (
          <a
            href={currentConversation.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 text-sm text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 mb-4"
          >
            <Link size={16} />
            {new URL(currentConversation.url).hostname}
          </a>
        )}
        <p className="text-gray-500 dark:text-gray-400 text-sm">
          {isGeneral
            ? "Ask me anything! I'm here to help with any questions you have."
            : isProjectWide
            ? "Ask me anything about your project. I can search through all project documents to help answer your questions."
            : currentConversation?.isWebContent
            ? "Ask me anything about this webpage. I've analyzed its content and can help answer your questions."
            : "Ask me anything about this document. I'm here to help analyze and explain its contents."}
        </p>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col h-full bg-gray-50 dark:bg-gray-900">
      {/* Mobile Header */}
      <div className="sticky top-0 z-20 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 sm:hidden">
        <div className="flex items-center justify-between px-4 py-2">
          <div className="flex items-center gap-3">
            <button 
              onClick={() => window.history.back()}
              className="p-1 -ml-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <ChevronLeft size={20} className="text-gray-600 dark:text-gray-300" />
            </button>
            <h1 className="font-semibold text-gray-900 dark:text-gray-100 truncate">
              {isGeneral
                ? "General Chat"
                : isProjectWide
                ? "Project Chat"
                : currentConversation?.fileName || "Chat"}
            </h1>
          </div>
          <Bot size={20} className="text-indigo-600 dark:text-indigo-400" />
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        <div className="max-w-5xl mx-auto px-2 sm:px-6 py-4 sm:py-6 space-y-4 sm:space-y-6">
          {messages.length > 0 && messages.some((m) => m.question || m.answer)
            ? messages
                .filter((m) => m.question || m.answer)
                .map((message, index) => (
                  <div key={index} className="space-y-4 sm:space-y-6">
                    {/* User Message */}
                    <div className="flex flex-col items-end gap-1 sm:gap-2 group">
                      <div className="flex items-start justify-end gap-2 w-full">
                        <div className="flex flex-col items-end">
                          <div className="bg-indigo-600 text-white rounded-2xl rounded-tr-none py-2 px-3 sm:px-4 max-w-[85%] sm:max-w-[90%] shadow-sm">
                            <p className="text-sm leading-relaxed break-words">
                              {message.question}
                            </p>
                          </div>
                          <span className="text-[10px] sm:text-xs text-gray-400 dark:text-gray-500 mt-1">
                            {user.firstName} • {formatTime(message.timestamp)}
                          </span>
                        </div>
                        <div className="w-6 h-6 sm:w-8 sm:h-8 bg-indigo-600 bg-opacity-10 dark:bg-opacity-20 rounded-full flex items-center justify-center flex-shrink-0">
                          <User
                            size={14}
                            className="text-indigo-600 dark:text-indigo-400"
                          />
                        </div>
                      </div>
                    </div>

                    {/* AI Response */}
                    <div className="flex items-start gap-2 group">
                      <div className="w-6 h-6 sm:w-8 sm:h-8 bg-purple-100 dark:bg-purple-900 rounded-full flex items-center justify-center flex-shrink-0">
                        <Bot
                          size={14}
                          className="text-purple-600 dark:text-purple-400"
                        />
                      </div>
                      <div className="flex flex-col flex-1 min-w-0">
                        <div className="bg-white dark:bg-gray-800 rounded-2xl rounded-tl-none py-2 px-3 sm:px-4 max-w-[85%] sm:max-w-[90%] shadow-sm">
                          {(loading && index === messages.length - 1) ||
                          (regenerating && index === messages.length - 1) ? (
                            <div className="flex items-center gap-2">
                              <Loader className="h-4 w-4 animate-spin text-gray-500 dark:text-gray-400" />
                              <p className="text-xs italic text-gray-500 dark:text-gray-400">
                                {regenerating
                                  ? "Regenerating response..."
                                  : messages.length > 10
                                  ? "This conversation is becoming too long. Lambda AI might take a bit longer to respond..."
                                  : isGeneral
                                  ? "Lambda AI is thinking..."
                                  : isProjectWide
                                  ? "Searching documents..."
                                  : currentConversation?.isWebContent
                                  ? "Analyzing webpage content..."
                                  : "Lambda AI is thinking..."}
                              </p>
                            </div>
                          ) : (
                            <div className="text-gray-900 dark:text-gray-100 break-words text-sm">
                              <ResponseRenderer content={message.answer || ""} />
                              {index === messages.length - 1 &&
                                relevantDocs.length > 0 && (
                                  <div className="mt-3 pt-3 border-t border-gray-200 dark:border-gray-700">
                                    <p className="text-xs text-gray-500 dark:text-gray-400 mb-2">
                                      Sources:
                                    </p>
                                    <div className="space-y-2">
                                      {relevantDocs
                                        .filter(
                                          (doc, index) =>
                                            relevantDocs.findIndex(
                                              (d) => d.fileName === doc.fileName
                                            ) === index
                                        )
                                        .map((doc, idx) => (
                                          <div
                                            key={idx}
                                            className="flex items-center gap-2 text-xs text-gray-600 dark:text-gray-300"
                                          >
                                            <FileText size={12} />
                                            <span className="truncate">
                                              {doc.fileName}
                                            </span>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                        <div className="flex items-center gap-2 mt-1">
                          <span className="text-[10px] sm:text-xs text-gray-400 dark:text-gray-500">
                            Lambda AI • {formatTime(message.timestamp)}
                          </span>
                          <MessageActions
                            onCopy={() => copyMessage(message.answer)}
                            onShare={() =>
                              shareResponse(message.question, message.answer)
                            }
                            onRegenerate={() =>
                              regenerateResponse(message.question)
                            }
                            onDownload={() => handleDownload(message.answer)}
                            isResponse
                            isRegenerating={
                              regenerating && index === messages.length - 1
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            : renderWelcomeMessage()}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {error && (
        <div className="fixed bottom-[72px] sm:bottom-[80px] left-0 right-0 px-4 py-2 bg-yellow-50 dark:bg-yellow-900/30 border-t border-yellow-100 dark:border-yellow-900">
          <p className="text-yellow-800 dark:text-yellow-300 text-sm max-w-3xl mx-auto">
            {error}
          </p>
        </div>
      )}

      {showShareAlert && (
        <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2 z-50">
          <AlertCircle className="h-4 w-4" />
          <span className="text-sm">Response copied to clipboard</span>
        </div>
      )}

      <form
        onSubmit={handleSubmit}
        className="sticky bottom-0 border-t border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900 p-2 sm:p-4 z-10"
      >
        <div className="max-w-3xl mx-auto relative">
          <div className="relative flex items-center gap-2">
            <button
              type="button"
              className="p-1.5 sm:p-2 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors cursor-pointer"
            >
              <Smile size={18} />
            </button>
            <div className="relative flex-1">
            <textarea
                ref={inputRef}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder={
                  isGeneral
                    ? "Ask me anything..."
                    : isProjectWide
                    ? "Ask about your project..."
                    : "Ask about this document..."
                }
                className="w-full p-3 pr-12 bg-white dark:bg-gray-800 border border-gray-200 
                dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 
                focus:ring-indigo-500 dark:focus:ring-indigo-400 resize-none text-sm 
                transition-all duration-200 text-gray-900 dark:text-white 
                placeholder-gray-500 dark:placeholder-gray-400"
                style={{ minHeight: "40px", maxHeight: "120px" }}
                disabled={loading}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    if (inputMessage.trim() && !loading) {
                      handleSubmit(e);
                    }
                  }
                }}
              />
              <button
                type="submit"
                disabled={loading || !inputMessage.trim()}
                className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 bg-indigo-600 
                text-white rounded-full hover:bg-indigo-700 dark:hover:bg-indigo-500 
                disabled:opacity-50 disabled:cursor-not-allowed transition-colors cursor-pointer
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
              >
                {loading ? (
                  <Loader className="h-4 w-4 animate-spin" />
                ) : (
                  <Send size={14} />
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatInterface;