import {
  Bell,
  FileText,
  Folders,
  HelpCircle,
  Home,
  Loader,
  LogOut,
  Menu,
  MessageSquare,
  Moon,
  Plus,
  Search,
  Settings,
  Star,
  Sun,
  Upload,
  Users,
  Globe,
  Wand2,
  X
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { handleUpload } from '../utils/uploadHandlers';
import GeneralUploadModal from "./GeneralUploadModal";
import HelpModal from "./HelpModal";
import { ProcessingErrorAlert } from './ProcessingErrorAlert';
import SearchResults from './SearchResults';
import UploadNotification from "./UploadNotification";

const Layout = ({ children }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [processingErrors, setProcessingErrors] = useState([]);

  useEffect(() => {
    const isDark = localStorage.getItem("darkMode") === "true";
    setIsDarkMode(isDark);
    if (isDark) {
      document.documentElement.classList.add("dark");
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle("dark");
    localStorage.setItem("darkMode", (!isDarkMode).toString());
  };

  const handleLogout = useCallback(() => {
    try {
      logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, [logout, navigate]);

  const handleFileUpload = useCallback((file, projectId) => {
    handleUpload({
      file,
      projectId,
      setNotifications,
      setProcessingErrors, 
      navigate,
      handleLogout,
    });
  }, [navigate, handleLogout]);

  const dismissNotification = useCallback((id) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  }, []);

  const dismissProcessingError = useCallback((id) => {
    setProcessingErrors((prev) => prev.filter((error) => error.id !== id));
  }, []);

  const handleSearch = useCallback(
    async (query) => {
      if (!query.trim()) {
        setSearchResults([]);
        return;
      }

      setIsSearching(true);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/search?q=${encodeURIComponent(
            query
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          handleLogout();
          return;
        }

        const data = await response.json();
        setSearchResults(data.results);
      } catch (error) {
        console.error("Search error:", error);
        setSearchResults([]);
      } finally {
        setIsSearching(false);
      }
    },
    [handleLogout]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchQuery) {
        handleSearch(searchQuery);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery, handleSearch]);

  const navigationItems = [
    ...(user.role === "admin"
      ? [{ icon: Home, label: "Dashboard", path: "/dashboard", badge: "Admin" }]
      : []),
      { icon: FileText, label: "Documents", path: "/documents", badge: "Trending" },
      { 
        icon: Wand2, 
        label: "General Chat", 
        path: "/general-chat",
        badge: "New" 
      },
    { icon: Globe, label: "Web Content", path: "/webcontent", badge: "New", }, 
    
    {
      icon: Folders,
      label: "Projects",
      path: "/projects",
      disabled: false,
      badge: "New",
    },
    { icon: MessageSquare, label: "Chat", path: "/chat" },
    { icon: Users, label: "Team", path: "/team", disabled: true },
    { icon: Star, label: "Favorites", path: "/favorites", disabled: true },
  ];

  return (
    <div className={`flex h-screen ${isDarkMode ? "dark" : ""}`}>
      {/* Mobile Sidebar Overlay */}
      {isSidebarOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed lg:relative z-50 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"
        } w-64 lg:w-72 h-full bg-white dark:bg-gray-900 border-r border-gray-200 dark:border-gray-800 transition-all duration-300 flex flex-col`}
      >
        {/* Logo Section */}
        <div className="h-16 flex items-center px-4 border-b border-gray-200 dark:border-gray-800">
          <img src="/logo.svg" alt="Logo" className="h-8 w-8" />
          <h1 className="ml-3 text-xl font-bold text-gray-900 dark:text-white">
            Lambda AI
          </h1>
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="lg:hidden ml-auto p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X size={20} />
          </button>
        </div>

        {/* Navigation */}
        <nav className="flex-1 py-4 overflow-y-auto px-2">
          {navigationItems.map((item) => (
            <button
              key={item.path}
              onClick={() => !item.disabled && navigate(item.path)}
              className={`flex items-center justify-between w-full px-3 py-2.5 mb-1 rounded-lg
              ${
                item.disabled
                  ? "opacity-60 cursor-not-allowed"
                  : location.pathname.startsWith(item.path)
                  ? "bg-indigo-50 text-indigo-600 dark:bg-indigo-900/50 dark:text-indigo-300"
                  : "text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800"
              } transition-colors duration-150`}
            >
              <div className="flex items-center">
                {React.createElement(item.icon, { size: 20 })}
                <span className="ml-3 font-medium">{item.label}</span>
              </div>
              {item.disabled ? (
                <span className="text-xs bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400 px-2 py-0.5 rounded-full">
                  Coming Soon
                </span>
              ) : item.badge ? (
                <span className="text-xs bg-green-100 dark:bg-green-900 text-green-600 dark:text-green-400 px-2 py-0.5 rounded-full">
                  {item.badge}
                </span>
              ) : null}
            </button>
          ))}
        </nav>

        {/* Sidebar Footer */}
        <div className="p-4 border-t border-gray-200 dark:border-gray-800 space-y-4">
          <button
            onClick={() => setIsUploadModalOpen(true)}
            className="flex items-center justify-center w-full p-2.5 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <Upload size={20} />
            <span className="ml-2 font-medium">Upload Document</span>
          </button>

          {/* Copyright Notice */}
          <div className="text-xs text-gray-500 dark:text-gray-400 text-center">
            <p>© 2024 Lambda AI. All rights reserved.</p>
            <p className="mt-1">For inquiries: lambdaa29@gmail.com</p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col min-w-0">
        {/* Header */}
        <header className="h-16 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800 flex items-center justify-between px-4 lg:px-6">
          <div className="flex items-center gap-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="lg:hidden p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <Menu size={20} />
            </button>

            <div className="flex-1 max-w-2xl relative hidden md:block">
              <div
                className={`relative ${
                  isSearchFocused
                    ? "ring-2 ring-indigo-600 dark:ring-indigo-500 rounded-lg"
                    : ""
                }`}
              >
                <Search
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500"
                  size={18}
                />
                <input
                  type="text"
                  placeholder="Search everything..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none dark:text-white"
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => {
                    setTimeout(() => setIsSearchFocused(false), 200);
                  }}
                />
                {isSearching && (
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    <Loader className="h-4 w-4 animate-spin text-gray-400 dark:text-gray-500" />
                  </div>
                )}
              </div>

              {isSearchFocused && (
                <SearchResults
                  results={searchResults}
                  searchQuery={searchQuery}
                  onSelect={(result) => {
                    if (result.type === "document") {
                      navigate(`/chat/${result.id}`);
                    } else if (result.type === "project") {
                      navigate(`/projects/${result.id}`);
                    }
                  }}
                  onClose={() => setIsSearchFocused(false)}
                />
              )}
            </div>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={toggleDarkMode}
              className="p-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
            >
              {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
            </button>

            <button
              onClick={() => setIsHelpModalOpen(true)}
              className="p-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
            >
              <HelpCircle size={20} />
            </button>

            <button className="p-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors relative">
              <Bell size={20} />
              {notifications.length > 0 && (
                <span className="absolute top-0 right-0 w-4 h-4 bg-red-500 text-white text-xs flex items-center justify-center rounded-full transform translate-x-1 -translate-y-1">
                  {notifications.length}
                </span>
              )}
            </button>

            <div className="relative">
              <button
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="flex items-center gap-2 p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
              >
                <div className="w-8 h-8 bg-indigo-600 dark:bg-indigo-500 rounded-full flex items-center justify-center text-white">
                  {user.firstName.charAt(0)}
                </div>
                {user.role === "admin" && (
                  <span className="text-xs bg-red-100 dark:bg-red-900 text-red-600 dark:text-red-300 px-2 py-0.5 rounded-full">
                    Admin
                  </span>
                )}
              </button>

              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-1 z-50">
                  <div className="px-4 py-3 border-b dark:border-gray-700">
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {user.firstName} {user.lastName}
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                      {user.email}
                    </p>
                  </div>

                  <Link
                    to='/settings'
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center gap-2"
                  >
                    <Settings size={16} />
                    Account Settings
                  </Link>

                  <div className="border-t dark:border-gray-700 mt-1 pt-1">
                    <button
                      onClick={handleLogout}
                      className="w-full px-4 py-2 text-left text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/50 flex items-center gap-2"
                    >
                      <LogOut size={16} />
                      Sign out
                    </button>
                  </div>
                </div>
                )}
                </div>
    
                <button
                  onClick={() => setIsUploadModalOpen(true)}
                  className="hidden md:flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                >
                  <Plus size={18} />
                  <span className="ml-2 text-sm font-medium">New</span>
                </button>
              </div>
            </header>
    
            {/* Mobile Search - Shown only on mobile */}
            <div className="md:hidden p-4 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800">
              <div className="relative">
                <Search
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500"
                  size={18}
                />
                <input
                  type="text"
                  placeholder="Search everything..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none dark:text-white"
                />
              </div>
            </div>
    
            {/* Main Content Area */}
            <main className="flex-1 overflow-auto bg-gray-50 dark:bg-gray-900">
              {/* Display any processing errors */}
              {processingErrors.length > 0 && (
                <div className="max-w-7xl mx-auto px-4 md:px-6 lg:px-8 pt-4">
                  {processingErrors.map((error) => (
                    <ProcessingErrorAlert
                      key={error.id}
                      fileName={error.fileName}
                      errorDetails={error.details}
                      onDismiss={() => dismissProcessingError(error.id)}
                    />
                  ))}
                </div>
              )}
              
              <div className="max-w-7xl mx-auto p-4 md:p-6 lg:p-8">
                {children}
              </div>
            </main>
    
            <div className="lg:hidden text-center py-4 text-xs text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800">
              <p>© 2024 Lambda AI. All rights reserved.</p>
              <p className="mt-1">For inquiries: lambdaa29@gmail.com</p>
            </div>
          </div>
    
          {/* Modals and Notifications */}
          <GeneralUploadModal
            isOpen={isUploadModalOpen}
            onClose={() => setIsUploadModalOpen(false)}
            onUpload={handleFileUpload}
          />
    
          <HelpModal
            isOpen={isHelpModalOpen}
            onClose={() => setIsHelpModalOpen(false)}
          />
    
          {/* Notifications */}
          <div className="fixed bottom-4 right-4 space-y-2 z-50">
            {notifications.map((notification) => (
              <UploadNotification
                key={notification.id}
                notification={notification}
                onDismiss={() => dismissNotification(notification.id)}
              />
            ))}
          </div>
        </div>
      );
    };
    
    export default Layout;