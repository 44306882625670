// components/dashboard/MetricCard.jsx
import { ArrowDownRight, ArrowUpRight } from 'lucide-react';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';

export const MetricCard = ({ title, value, icon: Icon, trend, trendValue, color = "indigo", subtitle }) => {
  const isPositive = trendValue >= 0;
  
  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700 hover:shadow-md transition-all duration-300">
      <div className="flex justify-between items-start">
        <div className="space-y-2">
          <div className="p-2 rounded-lg w-fit bg-indigo-100 dark:bg-indigo-900/30">
            <Icon className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />
          </div>
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</p>
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white tracking-tight">{value}</h3>
          {subtitle && (
            <p className="text-xs text-gray-500 dark:text-gray-400">{subtitle}</p>
          )}
          {trend && (
            <div className={`flex items-center text-sm ${isPositive ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
              {isPositive ? <ArrowUpRight className="w-4 h-4" /> : <ArrowDownRight className="w-4 h-4" />}
              <span className="ml-1 font-medium">{Math.abs(trendValue)}% {trend}</span>
            </div>
          )}
        </div>
        <div className="w-24 h-12">
          <ResponsiveContainer>
            <AreaChart data={[{ value: 20 }, { value: 40 }, { value: 30 }, { value: 50 }]}>
              <defs>
                <linearGradient id={`gradient-${title}`} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="rgb(79, 70, 229)" stopOpacity={0.4} />
                  <stop offset="100%" stopColor="rgb(79, 70, 229)" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area 
                type="monotone" 
                dataKey="value" 
                stroke="rgb(79, 70, 229)" 
                fill={`url(#gradient-${title})`}
                strokeWidth={2}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};