// components/dashboard/ErrorSummary.jsx
import { AlertCircle } from 'lucide-react';

// components/dashboard/ErrorSummary.jsx
export const ErrorSummary = ({ errors }) => {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Error Summary</h2>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Last {errors?.period?.days} days
          </span>
        </div>
  
        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 dark:bg-gray-900/50 rounded-lg">
              <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-1">Document Errors</h3>
              <div className="text-2xl font-bold text-gray-900 dark:text-white">
                {errors?.summary?.byType?.document || 0}
              </div>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-gray-900/50 rounded-lg">
              <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-1">Processing Errors</h3>
              <div className="text-2xl font-bold text-gray-900 dark:text-white">
                {errors?.summary?.byType?.processing || 0}
              </div>
            </div>
          </div>
  
          {errors?.documentErrors?.length > 0 ? (
            <div className="space-y-4">
              {errors.documentErrors.map((error, index) => (
                <div 
                  key={index}
                  className="flex items-start gap-4 p-3 rounded-lg bg-gray-50 dark:bg-gray-900/50"
                >
                                    <div className="p-2 bg-red-100 dark:bg-red-900/30 rounded-lg">
                  <AlertCircle className="w-4 h-4 text-red-600 dark:text-red-400" />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                    {error.type || 'Unknown Error'}
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                    {error.examples?.[0]?.fileName || 'Unknown file'}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {error.count} occurrences
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                    {new Date(error.examples?.[0]?.timestamp).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-6 bg-gray-50 dark:bg-gray-900/50 rounded-lg">
            <AlertCircle className="w-6 h-6 text-green-500 mx-auto" />
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">No errors reported in this period</p>
          </div>
        )}

        {errors?.processingErrors?.length > 0 && (
          <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-4">Processing Errors</h3>
            <div className="space-y-3">
              {errors.processingErrors.map((error, index) => (
                <div key={index} className="flex justify-between items-center text-sm">
                  <span className="text-gray-500 dark:text-gray-400">Project {error.projectId}</span>
                  <span className="text-gray-900 dark:text-white font-medium">{error.count} errors</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

