// components/search/SearchResults.jsx
import React from 'react';
import { FileText, Folders, MessageSquare } from 'lucide-react';

const SearchResults = ({ results, onSelect, onClose, searchQuery }) => {
  if (!searchQuery) return null;

  return (
    <div className="absolute top-full left-0 right-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 max-h-96 overflow-y-auto z-50">
      <div className="p-2 border-b dark:border-gray-700 sticky top-0 bg-white dark:bg-gray-800">
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {results.length === 0
            ? "No results found"
            : `${results.length} results`}
        </p>
      </div>
      {results.map((result) => (
        <button
          key={result.id}
          onClick={() => {
            onSelect(result);
            onClose();
          }}
          className="w-full text-left p-3 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-start gap-3"
        >
          <div
            className={`p-1.5 rounded-lg ${
              result.type === "document"
                ? "bg-blue-50 text-blue-600 dark:bg-blue-900 dark:text-blue-300"
                : result.type === "project"
                ? "bg-purple-50 text-purple-600 dark:bg-purple-900 dark:text-purple-300"
                : "bg-gray-50 text-gray-600 dark:bg-gray-700 dark:text-gray-300"
            }`}
          >
            {result.type === "document" ? (
              <FileText size={16} />
            ) : result.type === "project" ? (
              <Folders size={16} />
            ) : (
              <MessageSquare size={16} />
            )}
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-900 dark:text-white">
              {result.title}
            </h4>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
              {result.type.charAt(0).toUpperCase() + result.type.slice(1)} •{" "}
              {result.date}
            </p>
          </div>
        </button>
      ))}
    </div>
  );
};

export default SearchResults;