import {
  AlertCircle,
  Clock,
  Database,
  FileText,
  Users
} from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { ActivityChart } from "./ActivityChart";
import { ConversationStats } from "./ConversationStats";
import { ErrorSummary } from "./ErrorSummary";
import { KeyMetrics } from "./KeyMetrics";
import { ProcessingPerformance } from "./ProcessingPerformance";
import { ProjectPerformance } from "./ProjectPerformance";
import { SecurityOverview } from "./SecurityOverview";

export default function DashboardPage() {
  const [systemMetrics, setSystemMetrics] = useState(null);
  const [userActivity, setUserActivity] = useState(null);
  const [errorAnalytics, setErrorAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState(30);
  const [activeTab, setActiveTab] = useState("overview");

  useEffect(() => {
    const fetchAnalytics = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        const [metricsRes, activityRes, errorsRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/analytics/system`, {
            headers,
          }),
          fetch(
            `${process.env.REACT_APP_API_URL}/analytics/activity?days=${timeRange}`,
            { headers }
          ),
          fetch(
            `${process.env.REACT_APP_API_URL}/analytics/errors?days=${timeRange}`,
            { headers }
          ),
        ]);

        if (!metricsRes.ok || !activityRes.ok || !errorsRes.ok) {
          throw new Error("Failed to fetch analytics data");
        }

        const [metricsData, activityData, errorsData] = await Promise.all([
          metricsRes.json(),
          activityRes.json(),
          errorsRes.json(),
        ]);

        setSystemMetrics(metricsData);
        setUserActivity(activityData);
        setErrorAnalytics(errorsData);
        setError(null);
      } catch (err) {
        setError("Failed to fetch analytics data");
        console.error("Analytics fetch error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, [timeRange]);

  const tabs = useMemo(
    () => [
      { id: "overview", label: "Overview", icon: Database },
      { id: "users", label: "Users", icon: Users },
      { id: "content", label: "Content", icon: FileText },
      { id: "errors", label: "Errors", icon: AlertCircle },
    ],
    []
  );

  const renderContent = () => {
    switch (activeTab) {
      case "overview":
        return (
          <div className="space-y-6">
            <KeyMetrics metrics={systemMetrics} />

            <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
              <ActivityChart activity={userActivity} />
              <SecurityOverview metrics={systemMetrics} />
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
              <div className="xl:col-span-2">
                <ProjectPerformance metrics={systemMetrics} />
              </div>
              <ConversationStats metrics={systemMetrics} />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <ProcessingPerformance metrics={systemMetrics} />
              <ErrorSummary errors={errorAnalytics} />
            </div>
          </div>
        );

      case "users":
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="md:col-span-2">
                <SecurityOverview metrics={systemMetrics} />
              </div>
              <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">
                  Active Users
                </h2>
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Total Users</span>
                    <span className="text-lg font-bold">
                      {systemMetrics?.users?.total}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      Active this Month
                    </span>
                    <span className="text-lg font-bold">
                      {systemMetrics?.users?.activeLastMonth}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ActivityChart activity={userActivity} />
          </div>
        );

      case "content":
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <ProcessingPerformance metrics={systemMetrics} />
              <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">
                  Document Overview
                </h2>
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      Total Documents
                    </span>
                    <span className="text-lg font-bold">
                      {systemMetrics?.documents?.total}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      Successfully Processed
                    </span>
                    <span className="text-lg font-bold">
                      {systemMetrics?.documents?.processing?.completed}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      Average Embedding Size
                    </span>
                    <span className="text-lg font-bold">
                      {systemMetrics?.documents?.averageEmbeddingSize}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ProjectPerformance metrics={systemMetrics} />
            <ConversationStats metrics={systemMetrics} />
          </div>
        );

      case "errors":
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="md:col-span-2">
                <ErrorSummary errors={errorAnalytics} />
              </div>
              <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">
                  Error Summary
                </h2>
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Total Errors</span>
                    <span className="text-lg font-bold">
                      {errorAnalytics?.summary?.total}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      Document Errors
                    </span>
                    <span className="text-lg font-bold">
                      {errorAnalytics?.summary?.byType?.document}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      Processing Errors
                    </span>
                    <span className="text-lg font-bold">
                      {errorAnalytics?.summary?.byType?.processing}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ProcessingPerformance metrics={systemMetrics} />
          </div>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="relative">
          <div className="w-12 h-12 rounded-full border-4 border-indigo-100 dark:border-indigo-900 animate-spin border-t-indigo-600"></div>
          <div className="mt-4 text-sm text-center text-gray-500 dark:text-gray-400">
            Loading analytics...
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 max-w-2xl mx-auto">
        <div className="bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg p-4 flex items-center gap-3">
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <div>
            <h3 className="font-medium">Error Loading Analytics</h3>
            <p className="text-sm mt-1">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6 max-w-[1600px] mx-auto">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
        <div className="p-4 sm:p-6 border-b border-gray-200 dark:border-gray-700">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                Analytics Dashboard
              </h1>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                System analytics and monitoring for the last {timeRange} days
              </p>
            </div>
            <div className="flex flex-wrap items-center gap-3">
              <div className="flex bg-gray-100 dark:bg-gray-900 rounded-lg p-1">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`px-3 py-1.5 text-sm font-medium rounded-md transition-all flex items-center gap-2 ${
                      activeTab === tab.id
                        ? "bg-white dark:bg-gray-800 text-gray-900 dark:text-white shadow-sm"
                        : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                    }`}
                  >
                    <tab.icon className="w-4 h-4" />
                    {tab.label}
                  </button>
                ))}
              </div>
              <div className="flex items-center gap-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg px-3 py-1.5">
                <Clock className="w-4 h-4 text-gray-500" />
                <select
                  value={timeRange}
                  onChange={(e) => setTimeRange(Number(e.target.value))}
                  className="text-sm bg-transparent border-none focus:ring-0"
                >
                  <option value={7}>Last 7 days</option>
                  <option value={30}>Last 30 days</option>
                  <option value={90}>Last 90 days</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderContent()}
    </div>
  );
}
