// components/auth/RegisterForm.jsx
import axios from "axios";
import { motion } from "framer-motion";
import {
  ArrowLeft,
  Bot,
  ChevronRight,
  Eye,
  EyeOff,
  Folders,
  Globe,
  Lock,
  Mail,
  Sparkles,
  User,
  FileText,
  MessageSquare
} from "lucide-react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const features = [
    {
      icon: FileText,
      title: "Document Intelligence",
      description: "Chat with your documents naturally",
      gradient: "from-[#00FFB2] to-[#00D1FF]",
    },
    {
      icon: Bot,
      title: "Smart Assistant",
      description: "Get instant answers from your content with AI-powered insights",
      gradient: "from-[#00FFB2] via-[#00D1FF] to-[#00FFB2]",
    },
    {
      icon: Globe,
      title: "Web & Documents",
      description: "Process websites, files, and documents all in one place",
      gradient: "from-[#00D1FF] to-[#00FFB2]",
    },
    {
      icon: MessageSquare,
      title: "Interactive Chat",
      description: "Ask questions, get answers, and download responses easily",
      gradient: "from-[#00FFB2] to-[#00D1FF]",
    },
    {
      icon: Folders,
      title: "Project Organization",
      description: "Keep your content organized and accessible in projects",
      gradient: "from-[#00D1FF] to-[#00FFB2]",
    }
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    // Password strength validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      toast.error(
        "Password must be at least 8 characters long and contain uppercase, lowercase, number and special character"
      );
      return;
    }

    setIsLoading(true);
    const loadingToast = toast.loading("Creating your account...");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
        }
      );

      toast.success(
        "Account created! Please check your email to verify your account.",
        { id: loadingToast }
      );
      navigate("/login");
    } catch (error) {
      toast.error(error.response?.data?.error || "Registration failed", {
        id: loadingToast,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#0A0A0A_1px,transparent_1px),linear-gradient(to_bottom,#0A0A0A_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)] opacity-25">
          <div className="absolute inset-0 bg-gradient-to-r from-[#00FFB2]/10 to-[#00D1FF]/10" />
        </div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 py-16">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Left Column - Features */}
          <div className="space-y-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="space-y-4"
            >
              <div className="inline-flex items-center space-x-2 bg-white/5 rounded-full px-4 py-2 border border-white/10">
                <span className="w-2 h-2 rounded-full bg-[#00FFB2] animate-pulse" />
                <span className="text-sm">Join Our Platform</span>
              </div>
              <h1 className="text-5xl font-bold leading-tight">
                Welcome to
                <span className="block text-transparent bg-clip-text bg-gradient-to-r from-[#00FFB2] to-[#00D1FF]">
                  Lambda AI
                </span>
              </h1>
              <p className="text-lg text-gray-400 max-w-md">
                Experience the next generation of document analysis and
                processing
              </p>
            </motion.div>

            <div className="grid gap-6">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.6 }}
                  className="group bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10 hover:bg-white/10 transition-all"
                >
                  <div className="flex items-center gap-4">
                    <div
                      className={`p-3 rounded-xl bg-gradient-to-r ${feature.gradient}`}
                    >
                      <feature.icon className="w-6 h-6 text-black" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg mb-1 flex items-center gap-2">
                        {feature.title}
                        <ChevronRight className="w-4 h-4 opacity-0 -translate-x-4 group-hover:opacity-100 group-hover:translate-x-0 transition-all" />
                      </h3>
                      <p className="text-gray-400">{feature.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={() => navigate("/login")}
              className="flex items-center gap-2 text-gray-400 hover:text-[#00FFB2] transition-colors group"
            >
              <ArrowLeft className="w-5 h-5 group-hover:-translate-x-1 transition-transform" />
              <span>Back to login</span>
            </motion.button>
          </div>

          {/* Right Column - Registration Form */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="bg-white/5 backdrop-blur-sm rounded-2xl border border-white/10 p-8 relative overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-[#00FFB2]/10 to-[#00D1FF]/10 opacity-50" />

            <div className="relative space-y-8">
              <div className="text-center space-y-2">
                <div className="w-12 h-12 bg-gradient-to-r from-[#00FFB2] to-[#00D1FF] rounded-xl mx-auto flex items-center justify-center">
                  <Sparkles className="w-6 h-6 text-black" />
                </div>
                <h2 className="text-2xl font-bold">Create Account</h2>
                <p className="text-gray-400">Join thousands of users</p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="relative">
                    <User className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                    <input
                      name="firstName"
                      type="text"
                      required
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="w-full bg-white/5 border border-white/10 rounded-xl px-11 py-3 focus:ring-2 focus:ring-[#00FFB2] outline-none text-white placeholder:text-gray-500 transition-all"
                    />
                  </div>
                  <div className="relative">
                    <User className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                    <input
                      name="lastName"
                      type="text"
                      required
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="w-full bg-white/5 border border-white/10 rounded-xl px-11 py-3 focus:ring-2 focus:ring-[#00FFB2] outline-none text-white placeholder:text-gray-500 transition-all"
                    />
                  </div>
                </div>

                <div className="relative">
                  <Mail className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    name="email"
                    type="email"
                    required
                    placeholder="Email address"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full bg-white/5 border border-white/10 rounded-xl px-11 py-3 focus:ring-2 focus:ring-[#00FFB2] outline-none text-white placeholder:text-gray-500 transition-all"
                  />
                </div>

                <div className="relative">
                  <Lock className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full bg-white/5 border border-white/10 rounded-xl px-11 py-3 focus:ring-2 focus:ring-[#00FFB2] outline-none text-white placeholder:text-gray-500 transition-all"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-white transition-colors"
                  >
                    {showPassword ? (
                      <EyeOff className="w-5 h-5" />
                    ) : (
                      <Eye className="w-5 h-5" />
                    )}
                  </button>
                </div>

                <div className="relative">
                  <Lock className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="w-full bg-white/5 border border-white/10 rounded-xl px-11 py-3 focus:ring-2 focus:ring-[#00FFB2] outline-none text-white placeholder:text-gray-500 transition-all"
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-white transition-colors"
                  >
                    {showConfirmPassword ? (
                      <EyeOff className="w-5 h-5" />
                    ) : (
                      <Eye className="w-5 h-5" />
                    )}
                  </button>
                </div>

                <p className="text-sm text-gray-400">
                  Password must be at least 8 characters long and contain uppercase, lowercase, 
                  number and special character.
                </p>

                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  type="submit"
                  disabled={isLoading}
                  className="relative w-full bg-gradient-to-r from-[#00FFB2] to-[#00D1FF] text-black font-medium py-3 rounded-xl flex items-center justify-center gap-2 group hover:opacity-90 transition-all"
                >
                  <span>
                    {isLoading ? "Creating account..." : "Create account"}
                  </span>
                  <ChevronRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                </motion.button>
              </form>
            </div>
            <div className="text-center mt-8">
              <p className="text-sm text-gray-400">
                © 2024 Lambda AI • Developed by{" "}
                <a
                  href="mailto:lambdaa29@gmail.com"
                  className="text-[#00FFB2] hover:text-[#00D1FF] transition-colors"
                >
                  lambdaa29@gmail.com
                </a>
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;