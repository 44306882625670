import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Loader,
  ArrowLeft,
  File,
  Globe,
  MessageSquare,
  MoreVertical,
  Download,
} from "lucide-react";
import { useAuth } from "../context/AuthContext";
import ChatInterface from "./ChatInterface";

const ChatPage = () => {
  const { conversationId } = useParams();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [chatDetails, setChatDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!conversationId) {
      setLoading(false);
      return;
    }

    fetchChatDetails();
  }, [conversationId]);

  const fetchChatDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      let endpoint;
      let isWebContent = false;

      if (conversationId.startsWith('web-')) {
        const contentId = conversationId.replace('web-', '');
        endpoint = `/webcontent/chat/${conversationId}/history`;
        isWebContent = true;
      } else {
        endpoint = `/conversations/${conversationId}`;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        logout();
        navigate("/login");
        return;
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || 'Failed to load chat');

      if (isWebContent) {
        // Handle web content chat data
        setChatDetails({
          fileName: data.metadata?.title || 'Web Chat',
          url: data.metadata?.url,
          isWebContent: true,
          history: data.history || [],
          processingStatus: 'completed'
        });
      } else {
        // Handle regular conversation data
        if (data.conversation.user !== user._id && user.role !== "admin") {
          throw new Error("You don't have permission to access this conversation");
        }
        setChatDetails(data.conversation);
      }
    } catch (err) {
      if (err.message === "Unauthorized") {
        logout();
        navigate("/login");
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="h-8 w-8 animate-spin text-[#464EB8]" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg">
        <p className="font-medium">{error}</p>
        <button
          onClick={() => navigate(-1)}
          className="mt-4 text-[#464EB8] hover:text-[#373E93] flex items-center"
        >
          <ArrowLeft size={16} className="mr-1" /> Go Back
        </button>
      </div>
    );
  }

  if (!conversationId) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <MessageSquare size={48} className="text-gray-300 mb-4" />
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          Welcome {user.firstName}, select a document or website to start chatting
        </p>
        <div className="flex gap-4">
          <button
            onClick={() => navigate("/documents")}
            className="text-[#464EB8] hover:text-[#373E93] flex items-center"
          >
            <File className="w-4 h-4 mr-1" />
            Go to Documents
          </button>
          <button
            onClick={() => navigate("/webcontent")}
            className="text-[#464EB8] hover:text-[#373E93] flex items-center"
          >
            <Globe className="w-4 h-4 mr-1" />
            Go to Web Content
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[calc(100vh-12rem)]">
      {/* Header */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4 mb-6 overflow-hidden">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2 sm:gap-4 min-w-0">
            <button
              onClick={() => navigate(-1)}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg text-gray-600 dark:text-gray-400 transition-colors flex-shrink-0"
            >
              <ArrowLeft size={20} />
            </button>
            <div className="flex items-center gap-2 sm:gap-3 min-w-0">
              <div className="p-2 bg-blue-50 dark:bg-blue-900/50 text-blue-600 dark:text-blue-400 rounded-lg flex-shrink-0">
                {chatDetails?.isWebContent ? <Globe size={20} /> : <File size={20} />}
              </div>
              <div className="min-w-0">
                <h1 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white truncate">
                  {chatDetails?.fileName}
                  {!chatDetails?.isWebContent && user.role === "admin" && chatDetails?.userName && (
                    <span className="text-sm text-gray-500 dark:text-gray-400 ml-2 hidden sm:inline">
                      (Owned by {chatDetails.userName})
                    </span>
                  )}
                </h1>
                {chatDetails?.url && (
                  <a
                    href={chatDetails.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 truncate block"
                  >
                    {chatDetails.url}
                  </a>
                )}
                {chatDetails?.projectName && (
                  <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                    Project: {chatDetails.projectName}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center gap-1 sm:gap-2 flex-shrink-0">
            {!chatDetails?.isWebContent && (
              <button
                className="p-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                title="Download document"
              >
                <Download size={20} />
              </button>
            )}
            {((chatDetails?.user === user._id || user.role === "admin") && !chatDetails?.isWebContent) && (
              <button
                className="p-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                title="More options"
              >
                <MoreVertical size={20} />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Chat Interface */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm h-full overflow-hidden">
        <ChatInterface
          conversationId={conversationId}
          isAdmin={user.role === "admin"}
          userName={`${user.firstName} ${user.lastName}`}
          currentConversation={chatDetails}
        />
      </div>
    </div>
  );
};

export default ChatPage;