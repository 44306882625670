
// components/dashboard/ProcessingPerformance.jsx
// components/dashboard/ProcessingPerformance.jsx
export const ProcessingPerformance = ({ metrics }) => {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">Processing Performance</h2>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-600 dark:text-gray-400">Documents Processed</span>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {metrics?.documents?.processing?.completed} / {metrics?.documents?.total}
            </span>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
            <div 
              className="bg-green-600 h-2.5 rounded-full transition-all duration-500"
              style={{ width: `${metrics?.documents?.processing?.successRate || 0}%` }}
            />
          </div>
  
          <div className="mt-6">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-600 dark:text-gray-400">Average Embedding Size</span>
              <span className="text-sm font-medium text-gray-900 dark:text-white">
                {metrics?.documents?.averageEmbeddingSize || 0}
              </span>
            </div>
          </div>
  
          <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2">Success Rate</h3>
                <div className="text-2xl font-bold text-gray-900 dark:text-white">
                  {metrics?.documents?.processing?.successRate || 0}%
                </div>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                  {metrics?.documents?.processing?.completed} successful
                </p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2">Failed</h3>
                <div className="text-2xl font-bold text-gray-900 dark:text-white">
                  {metrics?.documents?.processing?.failed || 0}
                </div>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                  of {metrics?.documents?.total} total
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  