// components/dashboard/SecurityOverview.jsx
import { Lock, Mail, Shield } from 'lucide-react';

// components/dashboard/SecurityOverview.jsx
export const SecurityOverview = ({ metrics }) => {
    const securityMetrics = [
      {
        icon: Mail,
        title: "Email Verification",
        rate: metrics?.users?.security?.emailVerificationRate,
        total: metrics?.users?.total
      },
      {
        icon: Shield,
        title: "2FA Adoption",
        rate: metrics?.users?.security?.twoFactorAdoptionRate,
        total: metrics?.users?.total
      },
      {
        icon: Lock,
        title: "Account Security",
        subtitle: "Locked Accounts",
        count: metrics?.users?.security?.lockedAccounts
      }
    ];
  
    return (
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">Security Overview</h2>
        <div className="space-y-6">
          {securityMetrics.map((metric) => (
            <div key={metric.title} className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-indigo-100 dark:bg-indigo-900/30 rounded-lg">
                  <metric.icon className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">{metric.title}</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {metric.rate ? `${metric.rate}% enabled` : metric.subtitle}
                  </p>
                </div>
              </div>
              <span className="text-sm font-medium text-gray-900 dark:text-white">
                {metric.count ?? `${Math.round(metric.rate * metric.total / 100)} / ${metric.total}`}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };