// components/dashboard/KeyMetrics.jsx
import { Activity, FileText, Folders, Users } from 'lucide-react';
import { MetricCard } from './MetricCard';

export const KeyMetrics = ({ metrics }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      <MetricCard
        title="Total Users"
        value={metrics?.users?.total?.toLocaleString() || 0}
        icon={Users}
        trend="active this month"
        trendValue={(metrics?.users?.activeLastMonth / metrics?.users?.total * 100) || 0}
        subtitle={`${metrics?.users?.activeLastMonth} active users`}
      />
      <MetricCard
        title="Total Documents"
        value={metrics?.documents?.total?.toLocaleString() || 0}
        icon={FileText}
        trend="processing success"
        trendValue={parseFloat(metrics?.documents?.processing?.successRate) || 0}
        subtitle={`${metrics?.documents?.processing?.completed || 0} processed`}
      />
      <MetricCard
        title="Projects"
        value={metrics?.projects?.total?.toLocaleString() || 0}
        icon={Folders}
        trend="active projects"
        trendValue={(metrics?.projects?.active / metrics?.projects?.total * 100) || 0}
        subtitle={`${metrics?.projects?.active} active projects`}
      />
      <MetricCard
        title="Conversations"
        value={metrics?.conversations?.total?.toLocaleString() || 0}
        icon={Activity}
        trend="avg interactions"
        trendValue={metrics?.conversations?.interactions?.averagePerChat || 0}
        subtitle={`${metrics?.conversations?.interactions?.total} total interactions`}
      />
    </div>
  );
};