import React, { useEffect, useState } from 'react';
import { AlertCircle, File, X, Check, Loader } from 'lucide-react';

const UploadNotification = ({ notification, onDismiss }) => {
  const [progress, setProgress] = useState(notification.progress || 0);
  
  useEffect(() => {
    setProgress(notification.progress || 0);
  }, [notification.progress]);

  const getStatusConfig = () => {
    switch (notification.status) {
      case 'error':
        return {
          icon: AlertCircle,
          iconClass: 'text-red-600 dark:text-red-400',
          bgClass: 'bg-red-50 dark:bg-red-900/50 border-red-200 dark:border-red-800',
          textClass: 'text-red-600 dark:text-red-400'
        };
      case 'success':
        return {
          icon: Check,
          iconClass: 'text-green-600 dark:text-green-400',
          bgClass: 'bg-green-50 dark:bg-green-900/50 border-green-200 dark:border-green-800',
          textClass: 'text-green-600 dark:text-green-400'
        };
      default:
        return {
          icon: File,
          iconClass: 'text-blue-600 dark:text-blue-400',
          bgClass: 'bg-blue-50 dark:bg-blue-900/50 border-blue-200 dark:border-blue-800',
          textClass: 'text-blue-600 dark:text-blue-400'
        };
    }
  };

  const getErrorMessage = (errorDetails) => {
    if (!errorDetails) return "An unexpected error occurred";
    
    switch (errorDetails.type) {
      case 'EXTRACTION_FAILED':
        return 'Failed to extract text from the document. The file may be scanned/image-based, corrupted, or password protected.';
      case 'EMPTY_CONTENT':
        return 'No text content could be extracted from the document. The file might be empty or contain only images.';
      case 'EMBEDDING_FAILED':
        return 'Document was only partially processed. Some sections could not be analyzed.';
      case 'INVALID_FORMAT':
        return 'The file format is not supported.';
      default:
        return errorDetails.message || 'An unexpected error occurred while processing the document.';
    }
  };

  const config = getStatusConfig();
  const Icon = config.icon;

  return (
    <div className={`w-96 rounded-lg shadow-lg border p-4 ${config.bgClass} animate-slide-up`}>
      <div className="flex items-start gap-3">
        <div className={`p-2 rounded-lg ${config.iconClass} bg-white/20`}>
          {notification.status === 'processing' ? (
            <Loader className="h-5 w-5 animate-spin" />
          ) : (
            <Icon className="h-5 w-5" />
          )}
        </div>
        
        <div className="flex-1 min-w-0">
          <div className="flex items-start justify-between">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white">
              {notification.title || notification.fileName}
            </h4>
            <button
              onClick={() => onDismiss(notification.id)}
              className="p-1 hover:bg-white/20 rounded-md transition-colors"
            >
              <X size={14} className={config.textClass} />
            </button>
          </div>
          
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
            {notification.status === 'error' 
              ? getErrorMessage(notification.errorDetails)
              : notification.message}
          </p>

          {notification.status === 'processing' && (
            <div className="mt-2">
              <div className="h-1.5 w-full bg-white/20 rounded-full overflow-hidden">
                <div
                  className="h-full bg-blue-600 dark:bg-blue-500 rounded-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                Processing: {progress}%
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadNotification;