import React, { useState, useEffect, useRef } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark.css';  // Changed to dark theme
import { Check, Copy, ChevronDown, ChevronUp, Maximize, Minimize } from 'lucide-react';

const CodeBlock = ({ language, value, isStreaming }) => {
  const [copied, setCopied] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const codeRef = useRef(null);

  useEffect(() => {
    if (!isStreaming && codeRef.current) {
      hljs.highlightElement(codeRef.current);
    }
  }, [isStreaming, value]);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
    setCollapsed(false);
  };

  const lines = value.split('\n');
  const lineNumbers = Array.from({ length: lines.length }, (_, i) => i + 1);

  return (
    <div className={`relative mt-2 mb-2 sm:mt-4 sm:mb-4 group ${expanded ? 'fixed inset-0 z-50' : ''}`}>
      <div className="absolute top-2 right-2 flex items-center gap-0.5 rounded-md border border-gray-700 bg-gray-800">
        <button
          onClick={handleCopy}
          className="flex h-6 items-center gap-1.5 px-2 text-xs text-gray-300 hover:bg-gray-700 first:rounded-l-md"
          title={copied ? "Copied!" : "Copy to clipboard"}
        >
          {copied ? (
            <>
              <Check className="h-3.5 w-3.5" />
              Copied
            </>
          ) : (
            <>
              <Copy className="h-3.5 w-3.5" />
              Copy
            </>
          )}
        </button>
        <div className="h-3.5 w-px bg-gray-700" />
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="flex h-6 items-center px-2 text-gray-300 hover:bg-gray-700"
          title={collapsed ? "Expand" : "Collapse"}
        >
          {collapsed ? 
            <ChevronDown className="h-3.5 w-3.5" /> : 
            <ChevronUp className="h-3.5 w-3.5" />
          }
        </button>
        <div className="h-3.5 w-px bg-gray-700" />
        <button
          onClick={toggleExpand}
          className="flex h-6 items-center px-2 text-gray-300 hover:bg-gray-700 last:rounded-r-md"
          title={expanded ? "Minimize" : "Maximize"}
        >
          {expanded ? 
            <Minimize className="h-3.5 w-3.5" /> : 
            <Maximize className="h-3.5 w-3.5" />
          }
        </button>
      </div>

      <div className={`overflow-hidden transition-all duration-300 ease-in-out rounded-lg border border-gray-700 bg-gray-900 ${
        collapsed ? 'max-h-12' : expanded ? 'h-full' : 'max-h-[300px] sm:max-h-[500px]'
      }`}>
        <pre className={`p-2 sm:p-4 text-md sm:text-sm overflow-auto ${expanded ? 'h-full' : ''}`}>
          <div className="flex">
            <div className="pr-4 text-gray-500 select-none border-r border-gray-700">
              {lineNumbers.map(num => (
                <div key={num} className="text-right">{num}</div>
              ))}
            </div>
            <code
              ref={codeRef}
              className={`language-${language} flex-1 pl-4 ${isStreaming ? 'whitespace-pre-wrap break-all' : ''}`}
            >
              {value}
            </code>
          </div>
        </pre>
        
        {collapsed && (
          <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-gray-900 to-transparent pointer-events-none" />
        )}
      </div>
    </div>
  );
};

export default CodeBlock;