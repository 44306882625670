import { Loader, Upload, X, Folder } from "lucide-react";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  validateFile,
  getFileIcon,
  getFileCategory,
  getFileDescription,
  generateAcceptedTypes
} from '../utils/fileConfig';

const UploadModal = ({ isOpen, onClose, projectId, projectName, onUploadComplete, onNotification }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");

  const resetState = useCallback(() => {
    setFile(null);
    setError("");
    setDragActive(false);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen, resetState]);

  const handleFileValidation = (selectedFile) => {
    try {
      const validation = validateFile(selectedFile);
      if (validation.isValid) {
        setFile(selectedFile);
        setError("");
      } else {
        setError(validation.error);
        setFile(null);
      }
    } catch (err) {
      setError(err.message);
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file || !projectId) return;

    setUploading(true);
    setError("");

    const formData = new FormData();
    formData.append("document", file);
    formData.append("projectId", projectId);

    try {
      onNotification({
        id: Date.now().toString(),
        title: "Uploading Document",
        message: file.name,
        status: "processing",
        progress: 0,
      });

      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projectwide/documents/upload`,
        {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        }
      );

      if (response.status === 401) {
        logout();
        navigate("/login");
        return;
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      onNotification({
        id: Date.now().toString(),
        title: "Upload Complete",
        message: `${file.name} has been uploaded successfully`,
        status: "success",
        progress: 100,
      });

      onUploadComplete?.();
      onClose();

    } catch (error) {
      onNotification({
        id: Date.now().toString(),
        title: "Upload Failed",
        message: error.message,
        status: "error",
      });
      setError(error.message);
    } finally {
      setUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Upload Document
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
          >
            <X size={20} className="text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        {/* Project Indicator */}
        <div className="px-4 py-3 bg-gray-50 dark:bg-gray-700/50 border-b dark:border-gray-700">
          <div className="flex items-center gap-2">
            <div className="p-1.5 bg-indigo-100 dark:bg-indigo-900/30 rounded-md">
              <Folder size={16} className="text-indigo-600 dark:text-indigo-400" />
            </div>
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-300">Uploading to project:</p>
              <p className="text-sm font-medium text-gray-900 dark:text-white">{projectName}</p>
            </div>
          </div>
        </div>

        <div className="p-4 space-y-4">
          <div
            className={`p-8 border-2 border-dashed rounded-lg ${
              dragActive
                ? "border-blue-500 bg-blue-50 dark:bg-blue-900/20"
                : "border-gray-300"
            }`}
            onDragEnter={(e) => {
              e.preventDefault();
              setDragActive(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setDragActive(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setDragActive(true);
            }}
            onDrop={(e) => {
              e.preventDefault();
              setDragActive(false);
              const droppedFile = e.dataTransfer.files[0];
              if (droppedFile) {
                handleFileValidation(droppedFile);
              }
            }}
          >
            <div className="text-center">
              {file ? (
                <div className="flex flex-col items-center">
                  {React.createElement(getFileIcon(file.name), {
                    size: 48,
                    className: "text-blue-500"
                  })}
                  <span className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {getFileCategory(file.name)} - {getFileDescription(file.name)}
                  </span>
                </div>
              ) : (
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
              )}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {file ? file.name : "Drop your file here, or"}
                </label>
                <div className="mt-1 flex justify-center">
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => {
                      const selectedFile = e.target.files?.[0];
                      if (selectedFile) {
                        handleFileValidation(selectedFile);
                      }
                    }}
                    id="file-upload"
                    accept={generateAcceptedTypes()}
                  />
                  {!file && (
                    <label
                      htmlFor="file-upload"
                      className="cursor-pointer text-blue-600 dark:text-blue-400 hover:text-blue-500"
                    >
                      browse
                    </label>
                  )}
                </div>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                  Supports documents (PDF, DOCX, TXT) and code files up to 10MB
                </p>
              </div>
            </div>
          </div>

          {error && (
            <div className="p-3 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg text-sm">
              {error}
            </div>
          )}

          <div className="flex justify-end gap-3 pt-2">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleUpload}
              disabled={!file || uploading}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center"
            >
              {uploading ? (
                <>
                  <Loader className="mr-2 h-4 w-4 animate-spin" />
                  Uploading...
                </>
              ) : (
                "Upload"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;