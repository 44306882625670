// components/upload/ProcessingErrorAlert.jsx
import React from 'react';
import { AlertCircle, X } from 'lucide-react';

export const ProcessingErrorAlert = ({ status, fileName, errorDetails, onDismiss }) => {
  if (!errorDetails) return null;

  const getErrorMessage = () => {
    switch (errorDetails.type) {
      case 'EXTRACTION_FAILED':
        return 'Failed to extract text from the document. The file may be scanned/image-based, corrupted, or password protected.';
      case 'EMPTY_CONTENT':
        return 'No text content could be extracted from the document. The file might be empty or contain only images.';
      case 'EMBEDDING_FAILED':
        return 'Document was only partially processed. Some sections could not be analyzed.';
      case 'INVALID_FORMAT':
        return 'The file format is not supported.';
      default:
        return 'An unexpected error occurred while processing the document.';
    }
  };

  return (
    <div className="mb-4 bg-red-50 dark:bg-red-900/50 border border-red-200 dark:border-red-800 rounded-lg p-4">
      <div className="flex items-start gap-2">
        <AlertCircle className="h-5 w-5 text-red-600 dark:text-red-400 flex-shrink-0 mt-0.5" />
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-red-800 dark:text-red-300">
              Processing Error for {fileName}
            </h3>
            <button
              onClick={onDismiss}
              className="p-1 hover:bg-red-100 dark:hover:bg-red-800 rounded-md transition-colors"
            >
              <X size={14} className="text-red-600 dark:text-red-400" />
            </button>
          </div>
          <p className="mt-1 text-sm text-red-700 dark:text-red-300">
            {errorDetails.message || getErrorMessage()}
          </p>
        </div>
      </div>
    </div>
  );
};