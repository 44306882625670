// components/settings/UserSettings.jsx
import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { 
  Shield, 
  ToggleLeft, 
  ToggleRight, 
  User,
  Mail,
  Lock
} from "lucide-react";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";

const UserSettings = () => {
  const { user } = useAuth();
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setIs2FAEnabled(user.twoFactorEnabled || false);
    }
  }, [user]);

  const toggle2FA = async () => {
    setIsLoading(true);
    const loadingToast = toast.loading(
      is2FAEnabled ? "Disabling 2FA..." : "Enabling 2FA..."
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/toggle-2fa`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setIs2FAEnabled(response.data.twoFactorEnabled);
      toast.success(response.data.message, { id: loadingToast });
    } catch (error) {
      toast.error("Failed to update 2FA settings", { id: loadingToast });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold mb-8">Account Settings</h1>

      {/* Profile Information */}
      <div className="mb-8 bg-white/5 backdrop-blur-sm rounded-2xl border border-white/10 p-6">
        <div className="flex items-center gap-4 mb-6">
          <div className="p-3 rounded-xl bg-gradient-to-r from-[#00FFB2] to-[#00D1FF]">
            <User className="w-6 h-6 text-black" />
          </div>
          <div>
            <h2 className="text-xl font-semibold">Profile Information</h2>
            <p className="text-gray-400">Your account details</p>
          </div>
        </div>

        <div className="grid gap-4">
          <div className="flex items-center justify-between p-4 bg-white/5 rounded-xl">
            <div>
              <p className="text-sm text-gray-400">Full Name</p>
              <p>{user?.firstName} {user?.lastName}</p>
            </div>
          </div>
          <div className="flex items-center justify-between p-4 bg-white/5 rounded-xl">
            <div>
              <p className="text-sm text-gray-400">Email</p>
              <p>{user?.email}</p>
            </div>
            {user?.isEmailVerified ? (
              <span className="text-sm text-[#00FFB2]">Verified</span>
            ) : (
              <span className="text-sm text-red-500">Not Verified</span>
            )}
          </div>
        </div>
      </div>

      {/* Security Settings */}
      <div className="bg-white/5 backdrop-blur-sm rounded-2xl border border-white/10 p-6">
        <div className="flex items-center gap-4 mb-6">
          <div className="p-3 rounded-xl bg-gradient-to-r from-[#00FFB2] to-[#00D1FF]">
            <Shield className="w-6 h-6 text-black" />
          </div>
          <div>
            <h2 className="text-xl font-semibold">Security Settings</h2>
            <p className="text-gray-400">Manage your account security</p>
          </div>
        </div>

        <div className="space-y-4">
          {/* 2FA Toggle */}
          <div className="flex items-center justify-between p-4 bg-white/5 rounded-xl">
            <div className="flex items-center gap-4">
              <Lock className="w-5 h-5 text-gray-400" />
              <div>
                <p className="font-medium">Two-Factor Authentication</p>
                <p className="text-sm text-gray-400">
                  Add an extra layer of security to your account
                </p>
              </div>
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={toggle2FA}
              disabled={isLoading}
              className="text-[#00FFB2] hover:text-[#00D1FF] transition-colors"
            >
              {is2FAEnabled ? (
                <ToggleRight className="w-10 h-10" />
              ) : (
                <ToggleLeft className="w-10 h-10" />
              )}
            </motion.button>
          </div>

          {/* Password Reset Button */}
          <div className="flex items-center justify-between p-4 bg-white/5 rounded-xl">
            <div className="flex items-center gap-4">
              <Lock className="w-5 h-5 text-gray-400" />
              <div>
                <p className="font-medium">Password</p>
                <p className="text-sm text-gray-400">
                  Change your account password
                </p>
              </div>
            </div>
            <motion.button
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
              onClick={() => {/* Add password reset logic */}}
              className="px-4 py-2 bg-white/5 rounded-xl hover:bg-white/10 transition-all"
            >
              Change Password
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;