// components/dashboard/ConversationStats.jsx
export const ConversationStats = ({ metrics }) => {
    const chatTypes = [
      { type: 'Document', count: metrics?.conversations?.byType?.document || 0, color: 'bg-blue-500' },
      { type: 'General', count: metrics?.conversations?.byType?.general || 0, color: 'bg-green-500' },
      { type: 'Project-wide', count: metrics?.conversations?.byType?.projectWide || 0, color: 'bg-purple-500' }
    ];
  
    const totalChats = chatTypes.reduce((sum, type) => sum + type.count, 0);
  
    return (
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">Conversation Overview</h2>
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-gray-900 dark:text-white">Total Conversations</span>
            <span className="text-2xl font-bold text-gray-900 dark:text-white">{totalChats}</span>
          </div>
  
          <div className="space-y-4">
            {chatTypes.map(({ type, count, color }) => (
              <div key={type} className="space-y-2">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-500 dark:text-gray-400">{type}</span>
                  <span className="text-gray-900 dark:text-white font-medium">{count}</span>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div 
                    className={`${color} h-2 rounded-full`}
                    style={{ width: `${(count / totalChats * 100) || 0}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
  
          <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
            <div className="grid grid-cols-2 gap-4">
              <div className="text-center">
                <p className="text-sm text-gray-500 dark:text-gray-400">Total Interactions</p>
                <p className="text-xl font-bold text-gray-900 dark:text-white mt-1">
                  {metrics?.conversations?.interactions?.total}
                </p>
              </div>
              <div className="text-center">
                <p className="text-sm text-gray-500 dark:text-gray-400">Avg. Interactions/Chat</p>
                <p className="text-xl font-bold text-gray-900 dark:text-white mt-1">
                  {metrics?.conversations?.interactions?.averagePerChat}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };