import { Code, File, FileText } from 'lucide-react';

export const FILE_TYPES = {
  // Document types
  PDF: {
    mime: "application/pdf",
    icon: FileText,
    category: "Document",
    description: "PDF Document"
  },
  DOCX: {
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    icon: FileText,
    category: "Document",
    description: "Word Document"
  },
  DOC: {
    mime: "application/msword",
    icon: FileText,
    category: "Document",
    description: "Word Document"
  },
  XLSX: {
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: FileText,
    category: "Document",
    description: "Excel Spreadsheet"
  },
  XLS: {
    mime: "application/vnd.ms-excel",
    icon: FileText,
    category: "Document",
    description: "Excel Spreadsheet"
  },
  PPTX: {
    mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    icon: FileText,
    category: "Document",
    description: "PowerPoint Presentation"
  },
  PPT: {
    mime: "application/vnd.ms-powerpoint",
    icon: FileText,
    category: "Document",
    description: "PowerPoint Presentation"
  },
  TXT: {
    mime: "text/plain",
    icon: FileText,
    category: "Document",
    description: "Text Document"
  },
  CSV: {
    mime: "text/csv",
    icon: FileText,
    category: "Document",
    description: "CSV File"
  },
  RTF: {
    mime: "application/rtf",
    icon: FileText,
    category: "Document",
    description: "Rich Text Document"
  },
  
  // Code files
  JS: {
    mime: ["application/javascript", "text/javascript", "application/x-javascript"],
    icon: Code,
    category: "Code",
    description: "JavaScript"
  },
  JSX: {
    mime: "text/jsx",
    icon: Code,
    category: "Code",
    description: "React JSX"
  },
  TS: {
    mime: ["text/x-typescript", "application/typescript"],
    icon: Code,
    category: "Code",
    description: "TypeScript"
  },
  TSX: {
    mime: "text/tsx",
    icon: Code,
    category: "Code",
    description: "React TSX"
  },
  PY: {
    mime: "text/x-python",
    icon: Code,
    category: "Code",
    description: "Python"
  },
  JAVA: {
    mime: "text/x-java",
    icon: Code,
    category: "Code",
    description: "Java"
  },
  HTML: {
    mime: "text/html",
    icon: Code,
    category: "Code",
    description: "HTML"
  },
  CSS: {
    mime: "text/css",
    icon: Code,
    category: "Code",
    description: "CSS"
  },
  JSON: {
    mime: "application/json",
    icon: Code,
    category: "Code",
    description: "JSON"
  },
  MD: {
    mime: "text/markdown",
    icon: FileText,
    category: "Document",
    description: "Markdown"
  },
};

export const TEXT_FILE_EXTENSIONS = [
  'txt', 'js', 'jsx', 'ts', 'tsx', 'py', 'java', 'rb', 'php', 
  'cs', 'go', 'rs', 'cpp', 'c', 'css', 'html', 'json', 'yaml',
  'yml', 'xml', 'md', 'sh', 'bash', 'env', 'config', 'sql',
  'prisma', 'graphql', 'vue', 'svelte', 'scala', 'kt', 'swift'
];

export const DOCUMENT_EXTENSIONS = [
  'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'rtf', 'csv'
];

export const validateFile = (file) => {
  if (file.size > 10 * 1024 * 1024) {
    return {
      isValid: false,
      error: "File size exceeds 10MB limit"
    };
  }

  const extension = file.name.split('.').pop().toLowerCase();
  
  // Check if it's a registered file type
  if (FILE_TYPES[extension.toUpperCase()]) {
    return { isValid: true };
  }

  // Check if it's a known text or document extension
  if (TEXT_FILE_EXTENSIONS.includes(extension) || DOCUMENT_EXTENSIONS.includes(extension)) {
    return { isValid: true };
  }

  return {
    isValid: false,
    error: "Unsupported file type"
  };
};

export const getFileIcon = (fileName) => {
  const extension = fileName.split('.').pop().toUpperCase();
  return FILE_TYPES[extension]?.icon || File;
};

export const getFileCategory = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  
  if (DOCUMENT_EXTENSIONS.includes(extension)) {
    return "Document";
  }
  
  if (TEXT_FILE_EXTENSIONS.includes(extension)) {
    return "Code";
  }
  
  return FILE_TYPES[extension.toUpperCase()]?.category || "Unknown";
};

export const getFileDescription = (fileName) => {
  const extension = fileName.split('.').pop().toUpperCase();
  return FILE_TYPES[extension]?.description || extension.toUpperCase();
};

export const generateAcceptedTypes = () => {
  const mimeTypes = Object.values(FILE_TYPES).flatMap(type => 
    Array.isArray(type.mime) ? type.mime : [type.mime]
  );
  
  const allExtensions = [
    ...new Set([
      ...TEXT_FILE_EXTENSIONS,
      ...DOCUMENT_EXTENSIONS
    ])
  ];
  
  const extensions = allExtensions.map(ext => `.${ext.toLowerCase()}`);
  
  return [...new Set([...mimeTypes, ...extensions])].join(',');
};