import React from 'react';

const ThemeProvider = ({ children }) => {
  return (
    <div className="font-sans text-gray-900 bg-[#f5f5f5]">
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;500;600;700&display=swap');
        
        :root {
          --teams-primary: #464EB8;
          --teams-primary-dark: #373E93;
          --teams-sidebar: #292929;
          --teams-hover: #3B3B3B;
        }

        * {
          font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, sans-serif;
        }
      `}</style>
      {children}
    </div>
  );
};

export default ThemeProvider;