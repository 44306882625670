import {
  AlertCircle,
  Globe,
  Loader,
  MessageSquare,
  Trash2,
  X,
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WebContentCard from "./WebContentCard";

// Dropdown Menu Component
const ActionMenu = ({
  isOpen,
  onClose,
  onOpenChat,
  onDelete,
  isProcessing,
  isLoading,
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="absolute right-0 mt-1 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700 py-1 z-10"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        onClick={onOpenChat}
        disabled={isProcessing}
        className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center gap-2 disabled:opacity-50"
      >
        <MessageSquare className="w-4 h-4" />
        Open Chat
      </button>
      <button
        onClick={onDelete}
        disabled={isLoading}
        className="w-full px-4 py-2 text-left text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/50 flex items-center gap-2"
      >
        {isLoading ? (
          <Loader className="w-4 h-4 animate-spin" />
        ) : (
          <Trash2 className="w-4 h-4" />
        )}
        Delete
      </button>
    </div>
  );
};

// Status Badge Component
const StatusBadge = ({ status }) => {
  const statusStyles = {
    completed:
      "bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300",
    failed: "bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-300",
    processing:
      "bg-yellow-100 dark:bg-yellow-900 text-yellow-700 dark:text-yellow-300",
  };

  return (
    <span
      className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 justify-center ${statusStyles[status]}`}
    >
      {status === "processing" && <Loader className="w-3 h-3 animate-spin" />}
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

// Enhanced AddWebsiteModal Component
const AddWebsiteModal = ({ isOpen, onClose, onSuccess }) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contentId, setContentId] = useState(null);
  const [processingTime, setProcessingTime] = useState(0);
  const [processingStatus, setProcessingStatus] = useState(null);
  const [statusCheckFailed, setStatusCheckFailed] = useState(false);

  useEffect(() => {
    let timer;
    let statusCheck;
    let failedChecks = 0;
    const MAX_FAILED_CHECKS = 3; // After 3 failed status checks, show error

    if (processingStatus === "processing" && contentId) {
      timer = setInterval(() => {
        setProcessingTime((prev) => prev + 1);
      }, 1000);

      statusCheck = setInterval(async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/webcontent/${contentId}?status=true`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (!response.ok) throw new Error("Failed to check status");
          const data = await response.json();

          const mainContent = data.mainContent;
          if (mainContent.processingStatus === "completed") {
            clearInterval(statusCheck);
            clearInterval(timer);
            setProcessingStatus("completed");
            onSuccess();
            const chatId = `web-${contentId}`;
            navigate(`/chat/${chatId}`);
          } else if (mainContent.processingStatus === "failed") {
            clearInterval(statusCheck);
            clearInterval(timer);
            setProcessingStatus("failed");
            setError(
              mainContent.errorDetails?.message ||
                "Processing failed. Please try again."
            );
            setLoading(false);
          }
        } catch (error) {
          failedChecks++;
          console.error("Status check error:", error);
          if (failedChecks >= MAX_FAILED_CHECKS) {
            clearInterval(statusCheck);
            clearInterval(timer);
            setStatusCheckFailed(true);
            setError(
              "Failed to check processing status. Please try again later."
            );
            setLoading(false);
          }
        }
      }, 2000);
    }

    return () => {
      if (timer) clearInterval(timer);
      if (statusCheck) clearInterval(statusCheck);
    };
  }, [processingStatus, contentId, navigate, onSuccess]);

  const handleClose = () => {
    // Allow closing only if not actively processing or if there's an error
    if (
      !loading ||
      error ||
      statusCheckFailed ||
      processingStatus === "failed"
    ) {
      setUrl("");
      setError(null);
      setContentId(null);
      setProcessingTime(0);
      setProcessingStatus(null);
      setStatusCheckFailed(false);
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    setProcessingTime(0);
    setProcessingStatus(null);
    setContentId(null);
    setStatusCheckFailed(false);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/webcontent/scrape`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ url }),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to add website");
      }

      const data = await response.json();
      if (data.contentId) {
        setContentId(data.contentId);
        setProcessingStatus("processing");
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full mx-4">
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Add Website
          </h2>
          <button
            onClick={handleClose}
            className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            disabled={
              loading &&
              !error &&
              !statusCheckFailed &&
              processingStatus !== "failed"
            }
            title={
              loading && !error && !statusCheckFailed
                ? "Processing in progress..."
                : "Close"
            }
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Website URL
            </label>
            <input
              type="url"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://example.com"
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white text-sm"
              required
              disabled={loading}
            />
          </div>

          {error && (
            <div className="flex items-center gap-2 text-sm text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/30 p-3 rounded-md">
              <AlertCircle className="w-4 h-4 flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}

          {processingStatus === "processing" && (
            <div className="text-sm text-indigo-600 dark:text-indigo-400 bg-indigo-50 dark:bg-indigo-900/30 p-3 rounded-md">
              <div className="flex items-center mb-2">
                <Loader className="w-4 h-4 animate-spin mr-2" />
                <span>Processing website content...</span>
              </div>
              <div className="text-xs text-indigo-500 dark:text-indigo-300">
                Time elapsed: {formatTime(processingTime)}
              </div>
            </div>
          )}

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={handleClose}
              className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
              disabled={
                loading &&
                !error &&
                !statusCheckFailed &&
                processingStatus !== "failed"
              }
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 flex items-center"
            >
              {loading ? (
                <>
                  <Loader className="w-4 h-4 animate-spin mr-2" />
                  Processing...
                </>
              ) : (
                "Add Website"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Main WebContentPage Component
const WebContentPage = () => {
  const [webContents, setWebContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const fetchWebContents = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/webcontent/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch web contents");

      const data = await response.json();
      setWebContents(data.webContents);
      setError(null);
    } catch (error) {
      console.error("Fetch error:", error);
      setError("Failed to load web contents. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWebContents();
  }, [fetchWebContents]);

  const handleAddWebsite = () => {
    setIsAddModalOpen(true);
  };

  const handleModalClose = () => {
    setIsAddModalOpen(false);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64 space-y-4">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Loading web contents...
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div>
          <h1 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
            Web Content
          </h1>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Add and manage your website content for chat interactions
          </p>
        </div>
        <button
          onClick={handleAddWebsite}
          className="w-full sm:w-auto flex items-center justify-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors shadow-sm"
        >
          <Globe className="w-4 h-4 mr-2" />
          Add Website
        </button>
      </div>

      {error && (
        <div className="bg-red-50 dark:bg-red-900/30 p-4 rounded-lg flex items-center text-red-800 dark:text-red-300">
          <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
          <span className="text-sm">{error}</span>
        </div>
      )}

      {webContents.length === 0 && !error ? (
        <div className="text-center py-12">
          <Globe className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">
            No web content
          </h3>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Get started by adding a website to chat with.
          </p>
          <div className="mt-6">
            <button
              onClick={handleAddWebsite}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 dark:bg-indigo-900/30 dark:text-indigo-400 dark:hover:bg-indigo-900/50"
            >
              <Globe className="w-4 h-4 mr-2" />
              Add your first website
            </button>
          </div>
        </div>
      ) : (
        <div className="grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {webContents.map((content) => (
            <WebContentCard
              key={content.contentId}
              content={content}
              onRefresh={fetchWebContents}
            />
          ))}
        </div>
      )}

      <AddWebsiteModal
        isOpen={isAddModalOpen}
        onClose={handleModalClose}
        onSuccess={fetchWebContents}
      />
    </div>
  );
};

export default WebContentPage;
