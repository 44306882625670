import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CodeBlock from './CodeBlock';

const languageMap = {
  js: "javascript",
  jsx: "jsx",
  py: "python",
  html: "html",
  java: "java",
  cpp: "cpp",
  "c++": "cpp",
  ts: "typescript",
  tsx: "tsx",
  css: "css",
  ruby: "ruby",
  rb: "ruby",
  go: "go",
  rust: "rust",
  php: "php",
  swift: "swift",
  kotlin: "kotlin",
  scala: "scala",
  r: "r",
  shell: "bash",
  bash: "bash",
  sql: "sql",
  yaml: "yaml",
  yml: "yaml",
  json: "json",
  xml: "xml",
  markdown: "markdown",
  md: "markdown",
};

const CodeComponent = React.memo(({ inline, className, children }) => {
  if (inline || !className) {
    return (
      <code className="px-1 py-0.5 rounded bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 text-sm sm:text-sm font-mono">
        {children}
      </code>
    );
  }

  const match = /language-(\w+)/.exec(className || "");
  const lang = match && match[1] ? match[1].toLowerCase() : "";
  const language = languageMap[lang] || lang || "text";

  return (
    <CodeBlock
      language={language}
      value={String(children).replace(/\n$/, "")}
    />
  );
});

CodeComponent.displayName = 'CodeComponent';

const ParagraphWrapper = ({ children }) => {
  const containsHeading = React.Children.toArray(children).some(
    child => typeof child === 'object' && child.type && /^h[1-6]$/i.test(child.type)
  );

  if (containsHeading) {
    return children;
  }

  return (
    <p className="text-xs sm:text-sm mb-2 text-gray-700 dark:text-gray-300">{children}</p>
  );
};

const components = {
  code: CodeComponent,
  p: ParagraphWrapper,
  
  table: ({ children }) => (
    <div className="overflow-x-auto my-4">
      <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700 text-xs sm:text-sm border-collapse border border-gray-300 dark:border-gray-700">
        {children}
      </table>
    </div>
  ),
  
  thead: ({ children }) => (
    <thead className="bg-gray-50 dark:bg-gray-800">
      {children}
    </thead>
  ),

  tbody: ({ children }) => (
    <tbody className="divide-y divide-gray-300 dark:divide-gray-700 bg-white dark:bg-gray-800">
      {children}
    </tbody>
  ),

  tr: ({ children }) => (
    <tr className="hover:bg-gray-50 dark:hover:bg-gray-700/50">
      {children}
    </tr>
  ),
  
  th: ({ children }) => (
    <th className="px-2 py-1 sm:px-3 sm:py-2 text-left text-xs font-semibold text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600">
      {children}
    </th>
  ),
  
  td: ({ children }) => (
    <td className="px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600">
      {children}
    </td>
  ),
  
  a: ({ href, children }) => (
    <a
      href={href}
      className="text-indigo-600 dark:text-indigo-400 hover:underline text-xs sm:text-sm"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ),
  
  blockquote: ({ children }) => (
    <blockquote className="pl-2 sm:pl-4 border-l-4 border-indigo-500 dark:border-indigo-400 italic text-gray-700 dark:text-gray-300 text-xs sm:text-sm">
      {children}
    </blockquote>
  ),
  
  ul: ({ children }) => (
    <ul className="list-disc pl-4 sm:pl-5 space-y-1 text-xs sm:text-sm text-gray-700 dark:text-gray-300">
      {children}
    </ul>
  ),
  
  ol: ({ children }) => (
    <ol className="list-decimal pl-4 sm:pl-5 space-y-1 text-xs sm:text-sm text-gray-700 dark:text-gray-300">
      {children}
    </ol>
  ),
  
  h1: ({ children }) => (
    <h1 className="text-xl sm:text-2xl font-bold mt-6 mb-4 text-gray-900 dark:text-white">
      {children}
    </h1>
  ),
  
  h2: ({ children }) => (
    <h2 className="text-lg sm:text-xl font-bold mt-5 mb-3 text-gray-900 dark:text-white">
      {children}
    </h2>
  ),
  
  h3: ({ children }) => (
    <h3 className="text-base sm:text-lg font-bold mt-4 mb-2 text-gray-900 dark:text-white">
      {children}
    </h3>
  ),
  
  h4: ({ children }) => (
    <h4 className="text-sm sm:text-base font-bold mt-3 mb-2 text-gray-900 dark:text-white">
      {children}
    </h4>
  ),
  
  h5: ({ children }) => (
    <h5 className="text-xs sm:text-sm font-bold mt-2 mb-2 text-gray-900 dark:text-white">
      {children}
    </h5>
  ),
  
  h6: ({ children }) => (
    <h6 className="text-xs font-bold mt-2 mb-2 text-gray-900 dark:text-white">
      {children}
    </h6>
  ),

  // Add support for hr elements
  hr: () => (
    <hr className="my-4 border-gray-200 dark:border-gray-700" />
  ),

  // Add support for images
  img: ({ src, alt }) => (
    <img 
      src={src} 
      alt={alt} 
      className="max-w-full h-auto rounded-lg shadow-md dark:shadow-gray-800 my-4" 
    />
  ),
};

export const ResponseRenderer = ({ content }) => {
  return (
    <div className="text-xs sm:text-sm max-w-none prose prose-sm dark:prose-invert prose-pre:bg-transparent prose-pre:p-0">
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]}
        components={components}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

ResponseRenderer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ResponseRenderer;