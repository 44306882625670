// utils/uploadHandlers.js

export const handleUpload = async ({
  file,
  projectId,
  setNotifications,
  setProcessingErrors,
  navigate,
  handleLogout,
}) => {
  const notificationId = Date.now().toString();
  
  // Show upload progress
  setNotifications((prev) => [
    ...prev,
    {
      id: notificationId,
      title: "Uploading Document",
      message: file.name,
      status: "processing",
      progress: 0,
    },
  ]);

  const formData = new FormData();
  formData.append("document", file);

  if (projectId) {
    formData.append("projectId", projectId);
  }

  try {
    const token = localStorage.getItem("token");
    const endpoint = projectId
      ? "/projectwide/documents/upload"
      : "/documents/upload";

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.status === 401) {
      handleLogout();
      return;
    }

    const data = await response.json();
    if (!response.ok) throw new Error(data.error);

    // Show success message
    setNotifications((prev) =>
      prev.map((n) =>
        n.id === notificationId
          ? {
              id: notificationId,
              title: "Upload Complete",
              message: `${file.name} has been uploaded successfully`,
              status: "success",
              progress: 100,
            }
          : n
      )
    );

    // Navigate based on upload type
    if (projectId) {
      navigate(`/projects/${projectId}`);
    } else if (data.conversationId) {
      navigate(`/chat/${data.conversationId}`);
    }

  } catch (error) {
    // Show error message
    setNotifications((prev) =>
      prev.map((n) =>
        n.id === notificationId
          ? {
              id: notificationId,
              title: "Upload Failed",
              message: error.message || "An error occurred during upload",
              status: "error",
            }
          : n
      )
    );
  }
};