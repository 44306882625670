import { Upload, X } from 'lucide-react';
import React, { useState } from 'react';
import {
  generateAcceptedTypes,
  getFileCategory,
  getFileDescription,
  getFileIcon,
  validateFile
} from '../utils/fileConfig';

const GeneralUploadModal = ({ isOpen, onClose, onUpload }) => {
  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [fileError, setFileError] = useState("");

  const handleFileSelect = (selectedFile) => {
    const validation = validateFile(selectedFile);
    if (validation.isValid) {
      setFile(selectedFile);
      setFileError("");
    } else {
      setFile(null);
      setFileError(validation.error);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileSelect(e.dataTransfer.files[0]);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Upload Document
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
          >
            <X size={20} className="text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          <div
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            className={`p-8 border-2 border-dashed rounded-lg ${
              dragActive
                ? "border-indigo-500 bg-indigo-50 dark:bg-indigo-900/20"
                : "border-gray-300 dark:border-gray-600"
            } ${fileError ? "border-red-500" : ""} transition-colors duration-200`}
          >
            <div className="text-center">
              {file ? (
                <div className="flex flex-col items-center">
                  {React.createElement(getFileIcon(file.name), {
                    size: 48,
                    className: "text-indigo-500"
                  })}
                  <span className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {getFileCategory(file.name)} - {getFileDescription(file.name)}
                  </span>
                </div>
              ) : (
                <Upload className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" />
              )}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {file ? file.name : "Drop your file here, or"}
                </label>
                <div className="mt-1 flex justify-center">
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => handleFileSelect(e.target.files?.[0] || null)}
                    id="file-upload"
                    accept={generateAcceptedTypes()}
                  />
                  {!file && (
                    <label
                      htmlFor="file-upload"
                      className="cursor-pointer text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                    >
                      browse
                    </label>
                  )}
                </div>
                {fileError ? (
                  <p className="text-xs text-red-500 dark:text-red-400 mt-2">
                    {fileError}
                  </p>
                ) : (
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                    Supports documents (PDF, DOCX, TXT) and code files up to 10MB
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-2">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (file) {
                  onUpload(file);
                  onClose();
                }
              }}
              disabled={!file || !!fileError}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50 transition-colors"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralUploadModal;