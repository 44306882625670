import { AnimatePresence, motion } from "framer-motion";
import {
  Bot,
  ChevronRight,
  FileText,
  File,
  Table,
  Presentation,
  Globe,
  HelpCircle,
  MessageSquare,
  Folders,
  X,
  BookOpen,
  Settings,
  Code,
  Download,
  Play,
} from "lucide-react";
import React, { useState } from "react";

const GuideModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("features");

  if (!isOpen) return null;

  const tabs = [
    { id: "features", label: "Features", icon: BookOpen },
    { id: "preview", label: "Video Preview", icon: Play },
    { id: "usage", label: "Usage Guide", icon: MessageSquare },
    { id: "settings", label: "Settings", icon: Settings },
  ];

  const features = [
    {
      icon: Folders,
      title: "Project Management",
      description: "Organize and manage your documents intelligently",
      gradient: "from-purple-500 to-indigo-500",
      details: [
        {
          icon: Folders,
          format: "Project Organization",
          capabilities: [
            "Create and manage projects",
            "Group related documents",
            "Project-wide conversations",
            "Multi-document search",
          ],
        },
        {
          icon: MessageSquare,
          format: "Project Chat",
          capabilities: [
            "Chat across all project documents",
            "Smart document cross-referencing",
            "Interactive chat history",
            "Context-aware responses",
          ],
        },
      ],
    },
    {
      icon: FileText,
      title: "Document Processing",
      description: "Comprehensive support for multiple document formats",
      gradient: "from-blue-500 to-indigo-500",
      details: [
        {
          icon: File,
          format: "PDF Documents",
          capabilities: [
            "Extract and analyze PDF content",
            "Handle complex layouts",
            "Preserve document structure",
            "Process multi-page documents",
          ],
        },
        {
          icon: FileText,
          format: "Word Documents",
          capabilities: [
            "Process .docx and .doc files",
            "Extract raw text content",
            "Preserve document structure",
            "Handle all Word versions",
          ],
        },
        {
          icon: Table,
          format: "Excel Files",
          capabilities: [
            "Process .xlsx and .xls files",
            "Extract sheet-wise content",
            "Handle multiple sheets",
            "Parse tabular data",
          ],
        },
        {
          icon: Presentation,
          format: "PowerPoint Files",
          capabilities: [
            "Support for .pptx and .ppt",
            "Extract slide content",
            "Maintain slide ordering",
            "Process slide text",
          ],
        },
        {
          icon: Code,
          format: "Code Files",
          capabilities: [
            "Support for multiple languages",
            "Syntax-aware processing",
            "Handle various encodings",
            "Process configuration files",
          ],
        },
      ],
    },
    {
      icon: Globe,
      title: "Web Content",
      description: "Process and analyze web content intelligently",
      gradient: "from-green-500 to-teal-500",
      details: [
        {
          icon: Globe,
          format: "Web Processing",
          capabilities: [
            "Extract webpage content",
            "Handle dynamic content",
            "Process web metadata",
            "Smart content extraction",
          ],
        },
        {
          icon: MessageSquare,
          format: "Web Chat",
          capabilities: [
            "Chat about web content",
            "Track chat history",
            "Source verification",
            "Interactive responses",
          ],
        },
      ],
    },
    {
      icon: MessageSquare,
      title: "Chat Features",
      description: "Advanced conversation capabilities",
      gradient: "from-rose-500 to-pink-500",
      details: [
        {
          icon: Bot,
          format: "AI Interaction",
          capabilities: [
            "Natural language queries",
            "Context-aware responses",
            "Document references",
            "Follow-up questions",
          ],
        },
        {
          icon: Download,
          format: "Response Actions",
          capabilities: [
            "Download responses as DOCX",
            "Copy to clipboard",
            "Share conversations",
            "Regenerate responses",
          ],
        },
      ],
    },
  ];

  const usage = [
    {
      title: "Project Setup",
      steps: [
        "Create a new project",
        "Add documents or web content",
        "System processes content",
        "Start project-wide chat",
      ],
    },
    {
      title: "Document Interaction",
      steps: [
        "Upload supported files",
        "Wait for processing",
        "Ask questions naturally",
        "Get AI-powered responses",
      ],
    },
    {
      title: "Web Content",
      steps: [
        "Enter webpage URL",
        "System scrapes content",
        "Chat about the webpage",
        "Reference source content",
      ],
    },
    {
      title: "Advanced Features",
      steps: [
        "Cross-document analysis",
        "Download or share responses",
        "Rate response helpfulness",
        "Regenerate responses",
      ],
    },
  ];

  const settings = [
    {
      title: "Available Features",
      options: [
        "Chat with documents and websites",
        "Download conversations as Word files",
        "Share responses with others",
        "Organize content in projects",
      ],
    },
    {
      title: "Supported Files",
      options: [
        "Documents: PDF, Word, Excel, PowerPoint",
        "Text files and spreadsheets",
        "Websites and web content",
        "Code files and scripts",
      ],
    },
    {
      title: "Chat Options",
      options: [
        "Ask questions in natural language",
        "Get context-aware responses",
        "Rate helpful responses",
        "Regenerate answers if needed",
      ],
    },
  ];

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          className="bg-white dark:bg-gray-900 rounded-xl shadow-2xl w-full max-w-4xl overflow-hidden border border-gray-200 dark:border-gray-800"
        >
          {/* Header */}
          <div className="p-6 border-b border-gray-200 dark:border-gray-800">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-xl bg-gradient-to-r from-indigo-500 to-purple-500 flex items-center justify-center">
                  <Bot className="w-5 h-5 text-white" />
                </div>
                <div>
                  <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                    Lambda AI Guide
                  </h2>
                  <p className="text-sm text-gray-500">
                    Advanced document processing assistant
                  </p>
                </div>
              </div>
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
              >
                <X className="w-5 h-5 text-gray-500" />
              </button>
            </div>
          </div>

          {/* Custom Tabs */}
          <div className="border-b border-gray-200 dark:border-gray-800 overflow-hidden">
            <div className="overflow-x-auto scrollbar-none">
              <div className="flex p-2 gap-2 min-w-max">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium transition-colors whitespace-nowrap ${
                      activeTab === tab.id
                        ? "bg-indigo-500 text-white"
                        : "text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    }`}
                  >
                    <tab.icon className="w-4 h-4" />
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Tab Content */}
          <div className="p-6 max-h-[calc(100vh-300px)] overflow-y-auto">
            {activeTab === "preview" && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="aspect-video w-full bg-gray-100 dark:bg-gray-800 rounded-xl overflow-hidden"
              >
                <iframe
                  className="w-full h-full"
                  src="https://www.youtube.com/embed/xg2K-hdDMxg"
                  title="Lambda AI Preview"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </motion.div>
            )}

            {activeTab === "features" && (
              <div className="space-y-6">
                {features.map((feature) => (
                  <div key={feature.title} className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {feature.details.map((detail) => (
                        <motion.div
                          key={detail.format}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4 border border-gray-200 dark:border-gray-700"
                        >
                          <div className="flex items-center gap-3 mb-3">
                            <div className="w-8 h-8 rounded-lg bg-indigo-500/10 flex items-center justify-center">
                              <detail.icon className="w-4 h-4 text-indigo-500" />
                            </div>
                            <h3 className="font-medium text-gray-900 dark:text-white">
                              {detail.format}
                            </h3>
                          </div>
                          <ul className="space-y-2">
                            {detail.capabilities.map((capability, i) => (
                              <li
                                key={i}
                                className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400"
                              >
                                <ChevronRight className="w-4 h-4 text-indigo-500" />
                                {capability}
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {activeTab === "usage" && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {usage.map((section, index) => (
                  <motion.div
                    key={section.title}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4 border border-gray-200 dark:border-gray-700"
                  >
                    <h3 className="font-medium text-gray-900 dark:text-white mb-4">
                      {section.title}
                    </h3>
                    <ul className="space-y-3">
                      {section.steps.map((step, i) => (
                        <li key={i} className="flex items-start gap-3">
                          <span className="w-6 h-6 bg-indigo-500/10 rounded-full flex items-center justify-center text-xs text-indigo-500 flex-shrink-0">
                            {i + 1}
                          </span>
                          <span className="text-sm text-gray-600 dark:text-gray-400">
                            {step}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                ))}
              </div>
            )}

            {activeTab === "settings" && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {settings.map((section) => (
                  <motion.div
                    key={section.title}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4 border border-gray-200 dark:border-gray-700"
                  >
                    <h3 className="font-medium text-gray-900 dark:text-white mb-4">
                      {section.title}
                    </h3>
                    <ul className="space-y-2">
                      {section.options.map((option, i) => (
                        <li
                          key={i}
                          className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400"
                        >
                          <ChevronRight className="w-4 h-4 text-indigo-500" />
                          {option}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                ))}
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="p-4 border-t border-gray-200 dark:border-gray-800 flex items-center justify-between bg-gray-50 dark:bg-gray-800/50">
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <HelpCircle className="w-4 h-4" />
              Press ? key anytime to show this guide
            </div>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg text-sm font-medium transition-colors"
            >
              Got it
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default GuideModal;
