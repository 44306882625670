import React, { useState, useEffect } from 'react';
import { FileText, Trash2, MessageSquare, Loader, File, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import toast from 'react-hot-toast';

const DocumentCard = ({ doc, onDelete, onChat, userName }) => {
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    const commonTypes = {
      pdf: 'text-red-500 dark:text-red-400',
      doc: 'text-indigo-600 dark:text-indigo-400',
      docx: 'text-indigo-600 dark:text-indigo-400',
      txt: 'text-gray-600 dark:text-gray-400'
    };

    return commonTypes[extension] || 'text-indigo-600 dark:text-indigo-400';
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).format(new Date(date));
  };

  const formatTime = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(new Date(date));
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200 dark:border-gray-700 hover:border-indigo-500/50 dark:hover:border-indigo-500/50">
      {/* Header */}
      <div className="p-4 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-start space-x-3">
          <div className={`p-2 bg-gray-50 dark:bg-gray-700 rounded-lg ${getFileIcon(doc.fileName)}`}>
            <File size={24} />
          </div>
          <div className="flex-1 min-w-0">
            <h3 className="font-medium text-gray-900 dark:text-white truncate mb-1" title={doc.fileName}>
              {doc.fileName}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Added {formatDate(doc.createdAt)}
            </p>
          </div>
        </div>
      </div>

      {/* Actions */}
      <div className="px-4 py-3 bg-gray-50 dark:bg-gray-800/50 rounded-b-xl flex items-center justify-between">
        <div className="text-xs text-gray-500 dark:text-gray-400 truncate mr-2">
          {doc.history?.length > 0 ? (
            <span title={new Date(doc.history[doc.history.length - 1].timestamp).toLocaleString()}>
              Last chat by {userName}: {formatTime(doc.history[doc.history.length - 1].timestamp)}
            </span>
          ) : (
            <span>No interactions yet</span>
          )}
        </div>
        <div className="flex space-x-1 flex-shrink-0">
          <button
            onClick={() => onChat(doc.conversationId)}
            className="p-2 hover:bg-white dark:hover:bg-gray-700 rounded-lg transition-colors group"
            title="Chat with document"
          >
            <MessageSquare 
              size={18} 
              className="text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors" 
            />
          </button>
          <button
            onClick={() => onDelete(doc.conversationId)}
            className="p-2 hover:bg-white dark:hover:bg-gray-700 rounded-lg transition-colors group"
            title="Delete document"
          >
            <Trash2 
              size={18} 
              className="text-gray-400 group-hover:text-red-600 dark:group-hover:text-red-400 transition-colors" 
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const DocumentsPage = () => {
  const { user, logout } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // current page
  const [totalPages, setTotalPages] = useState(1); // total pages
  const [totalDocuments, setTotalDocuments] = useState(0); // total documents
  const navigate = useNavigate();

  useEffect(() => {
    fetchDocuments();
  }, [currentPage]);

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/document-operations/list?page=${currentPage}&limit=6`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 401) {
        logout();
        navigate('/login');
        return;
      }
      
      const data = await response.json();
      if (!response.ok) throw new Error(data.error);
      
      setDocuments(data.documents);
      setTotalPages(data.totalPages); // Set total pages from the response
      setTotalDocuments(data.totalDocuments); // Set total documents from the response
    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (conversationId) => {
    const confirmToast = toast((t) => (
      <div className="flex flex-col space-y-2">
        <p className="font-medium">Delete this document?</p>
        <div className="flex space-x-2">
          <button
            className="px-3 py-1 bg-red-600 text-white rounded-md text-sm hover:bg-red-700"
            onClick={() => {
              deleteDocument(conversationId);
              toast.dismiss(t.id);
            }}
          >
            Delete
          </button>
          <button
            className="px-3 py-1 bg-gray-200 text-gray-800 rounded-md text-sm hover:bg-gray-300"
            onClick={() => toast.dismiss(t.id)}
          >
            Cancel
          </button>
        </div>
      </div>
    ));
  };

  const deleteDocument = async (conversationId) => {
    const loadingToast = toast.loading('Deleting document...');
    
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/document-operations/delete/${conversationId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 401) {
        logout();
        navigate('/login');
        return;
      }
      
      if (!response.ok) throw new Error('Failed to delete document');
      
      setDocuments(docs => docs.filter(doc => doc.conversationId !== conversationId));
      toast.success('Document deleted successfully', { id: loadingToast });
    } catch (err) {
      toast.error(err.message, { id: loadingToast });
    }
  };

  const handlePagination = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <Loader className="h-8 w-8 animate-spin text-indigo-600 dark:text-indigo-400" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg flex items-center gap-2 p-4">
        <AlertCircle className="h-5 w-5 flex-shrink-0" />
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Welcome, {user.firstName}
        </h1>
        <p className="text-gray-500 dark:text-gray-400">
          {totalDocuments} {totalDocuments === 1 ? 'document' : 'documents'}
        </p>
      </div>
      
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
        {documents.map((doc) => (
          <DocumentCard
            key={doc.conversationId}
            doc={doc}
            onDelete={handleDelete}
            onChat={(conversationId) => navigate(`/chat/${conversationId}`)}
            userName={`${user.firstName} ${user.lastName}`}
          />
        ))}
      </div>
      
      {documents.length === 0 && (
        <div className="text-center py-12 bg-gray-50 dark:bg-gray-800/50 rounded-xl border border-dashed border-gray-200 dark:border-gray-700">
          <FileText size={48} className="mx-auto text-gray-400 dark:text-gray-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            No documents yet, {user.firstName}
          </h3>
          <p className="text-gray-500 dark:text-gray-400 mt-1 max-w-sm mx-auto">
            Upload your first document to start chatting with your content
          </p>
        </div>
      )}
      
      {/* Pagination controls */}
      <div className="flex justify-center gap-4 mt-6">
        <button
          onClick={() => handlePagination(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 rounded-md text-sm text-gray-700 disabled:bg-gray-200"
        >
          Previous
        </button>
        <span className="self-center text-sm text-gray-700 dark:text-gray-300">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePagination(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-300 rounded-md text-sm text-gray-700 disabled:bg-gray-200"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DocumentsPage;
