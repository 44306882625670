// components/dashboard/ActivityChart.jsx
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomTooltip } from './CustomTooltip';

// components/dashboard/ActivityChart.jsx
export const ActivityChart = ({ activity }) => {
    // Combine login and chat activity data
    const combinedData = activity?.loginActivity?.map(login => {
      const chatData = activity?.chatActivity?.find(chat => chat.date === login.date);
      return {
        date: login.date,
        uniqueUsers: login.uniqueUsers,
        totalLogins: login.totalLogins,
        interactions: chatData?.totalInteractions || 0,
        ...chatData?.byType
      };
    }) || [];
  
    return (
      <div className="xl:col-span-2 bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-6 gap-4">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">System Activity</h2>
          <div className="flex flex-wrap items-center gap-4">
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-indigo-600 rounded-full"></div>
              <span className="text-sm text-gray-600 dark:text-gray-400">Logins</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-cyan-500 rounded-full"></div>
              <span className="text-sm text-gray-600 dark:text-gray-400">Unique Users</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-emerald-500 rounded-full"></div>
              <span className="text-sm text-gray-600 dark:text-gray-400">Interactions</span>
            </div>
          </div>
        </div>
        <div className="h-[300px] mt-4">
          <ResponsiveContainer>
            <LineChart data={combinedData}>
              <CartesianGrid strokeDasharray="3 3" className="stroke-gray-200 dark:stroke-gray-700" />
              <XAxis 
                dataKey="date" 
                className="text-xs" 
                tickFormatter={(value) => new Date(value).toLocaleDateString()}
              />
              <YAxis className="text-xs" />
              <Tooltip content={<CustomTooltip />} />
              <Line 
                type="monotone" 
                dataKey="totalLogins" 
                name="Total Logins"
                stroke="#4f46e5" 
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line 
                type="monotone" 
                dataKey="uniqueUsers" 
                name="Unique Users"
                stroke="#06b6d4" 
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line 
                type="monotone" 
                dataKey="interactions" 
                name="Interactions"
                stroke="#10b981" 
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };