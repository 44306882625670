// components/dashboard/ProjectPerformance.jsx
// components/dashboard/ProjectPerformance.jsx
export const ProjectPerformance = ({ metrics }) => {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Project Overview</h2>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            <span className="font-medium text-gray-900 dark:text-white">{metrics?.projects?.active}</span> active of{' '}
            <span className="font-medium text-gray-900 dark:text-white">{metrics?.projects?.total}</span> total
          </div>
        </div>
        
        <div className="grid gap-6">
          <div>
            <div className="flex justify-between text-sm mb-2">
              <span className="text-gray-500 dark:text-gray-400">Project Activity</span>
              <span className="font-medium text-gray-900 dark:text-white">
                {metrics?.projects?.totalChats} total chats
              </span>
            </div>
            <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
              <div 
                className="bg-indigo-600 h-2.5 rounded-full transition-all duration-500"
                style={{ 
                  width: `${(metrics?.projects?.active / metrics?.projects?.total * 100) || 0}%` 
                }}
              />
            </div>
          </div>
  
          {metrics?.projects?.total > 0 ? (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-200 dark:border-gray-700">
                    <th className="text-left py-3 px-4 text-sm font-medium text-gray-500 dark:text-gray-400">Project Name</th>
                    <th className="text-right py-3 px-4 text-sm font-medium text-gray-500 dark:text-gray-400">Documents</th>
                    <th className="text-right py-3 px-4 text-sm font-medium text-gray-500 dark:text-gray-400">Chats</th>
                  </tr>
                </thead>
                <tbody>
                  {metrics?.projects?.topProjects?.map((project, index) => (
                    <tr key={index} className="border-b border-gray-200 dark:border-gray-700 last:border-0">
                      <td className="py-3 px-4">
                        <div className="flex items-center">
                          <div className="w-2 h-2 rounded-full bg-indigo-600 mr-2"></div>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">
                            {project.name || `Project ${index + 1}`}
                          </span>
                        </div>
                      </td>
                      <td className="text-right py-3 px-4 text-sm text-gray-500 dark:text-gray-400">
                        {project.documentCount?.toLocaleString() || 0}
                      </td>
                      <td className="text-right py-3 px-4 text-sm text-gray-500 dark:text-gray-400">
                        {project.chatCount?.toLocaleString() || 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center py-6">
              <p className="text-sm text-gray-500 dark:text-gray-400">No active projects</p>
            </div>
          )}
  
          <div className="grid grid-cols-3 gap-4 pt-4 border-t border-gray-200 dark:border-gray-700">
            <div className="text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">Average Chats</p>
              <p className="text-lg font-bold text-gray-900 dark:text-white mt-1">
                {metrics?.projects?.averageChatsPerProject}
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">Total Chats</p>
              <p className="text-lg font-bold text-gray-900 dark:text-white mt-1">
                {metrics?.projects?.totalChats}
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">Activity Rate</p>
              <p className="text-lg font-bold text-gray-900 dark:text-white mt-1">
                {((metrics?.projects?.active / metrics?.projects?.total) * 100 || 0).toFixed(1)}%
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
